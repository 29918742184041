import { ToastContainer } from "react-toastify";

import Routes from "./routes/Routes";
import style from "./assets/style/admin.css";
import responsive from "./assets/style/responsive.css";
import "./container/admin/pages/comingSoon/comingSoon.css";
import "./container/admin/pages/History/history.css";
import "react-toastify/dist/ReactToastify.css";
import "../src/container/admin/pages/roleMaster/rolepermission.css";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";

// 1. Get projectId
const projectId = "2d7ded4b2b1becfe9064b344d8c4ba63";

// 2. Set chains
const Goerli = {
  chainId: 5,
  name: "Goerli",
  currency: "ETH",
  explorerUrl: "https://etherscan.io",
  rpcUrl: "https://goerli.infura.io/v3/c699d17f7200444c8ffa9c281ed20452",
};

const BSCTestnet = {
  chainId: 97,
  name: "BNB Testnet",
  currency: "BNB",
  explorerUrl: "https://etherscan.io",
  rpcUrl: "https://bsc-dataseed1.binance.org/",
};

const BNBSmartChainMainnet = {
  chainId: 56,
  name: "BNB Smart Chain Mainnet",
  currency: "BNB",
  explorerUrl: "https://bscscan.com/",
  rpcUrl: "https://bsc-dataseed1.binance.org/",
};

// 3. Create modal
const metadata = {
  name: "Plutope Presale",
  description: "presale for  plutope token ",
  url: "https://presale.plutope.io/",
  icons: ["https://presale.plutope.io/favicon.ico"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [BNBSmartChainMainnet],
  projectId,
  featuredWalletIds: [
    "91b9a02134bc834b96ebfa333a0bf27ac6372ac56682b5e77bf8577abe0eea78",
  ],
});

function App() {
  // {
  //   console.log = () => {};
  //   console.error = () => {};
  //   console.debug = () => {};
  // }

  return (
    <div className="App">
      <Routes />
      <ToastContainer />
    </div>
  );
}

export default App;
