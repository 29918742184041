import logo from "./images/svg/logo.svg";
import dashboard from "./images/svg/dashboard.svg";
import userlist from "./images/svg/user-list.svg";
import adduser from "./images/svg/add-user.svg";
import editprofile from "./images/svg/edit-profile.svg";
import changepassword from "./images/svg/change-password.svg";
import addusermodal from "./images/svg/add-user-modal.svg";
import settings from "./images/svg/setting.svg";
import notification from "./images/svg/notification.svg";
import search from "./images/svg/search.svg";
import save from "./images/svg/save.svg";
import close from "./images/svg/close.svg";
import filter from "./images/svg/filter.svg";
import plus from "./images/svg/plus.svg";
import blueedit from "./images/svg/blue-edit.svg";
import yelloweye from "./images/svg/yellow-eye.svg";
import trash from "./images/svg/trash.svg";
import exporticon from "./images/svg/export.svg";
import modalclose from "./images/svg/modal-close.svg";
import logout from "./images/svg/logout.svg";
import bergurmenu from "./images/svg/bergur-menu.svg";
import edit from "./images/svg/edit.svg";
import upload from "./images/svg/upload.svg";
import genres from "./images/svg/genre.svg";
import games from "./images/svg/games.svg";
import check from "./images/svg/check.svg";
// import delete from './images/svg/delete.svg';
import copy from "./images/svg/copy.svg";
import copyIcon from "./images/svg/copyIcon"
import cms from "./images/svg/cms.svg";
import exportIcon from "./images/svg/export-button-svgrepo-com.svg";

import newspaper from "./images/svg/newspaper.svg";
import review from "./images/svg/review.svg";
import network from "./images/svg/network.svg";
import platform from "./images/svg/platform.svg";
import status from "./images/svg/status.svg";
import guide from "./images/svg/guide.svg";
import role from "./images/svg/role.svg";
import subadmin from "./images/svg/sub-admin.svg";
import editorchoice from "./images/svg/editor-choice.svg";
import unverified from "./images/svg/delete.svg";
import partner from "./images/svg/partner.svg";
import team from "./images/svg/team.svg";
import slidergaming from "./images/svg/slider-gaming.svg";
import slider from "./images/svg/slider.svg";
import event from "./images/svg/event.svg";
import very from "./images/svg/very.svg";
import quest from "./images/svg/quest.svg";
import users from "./images/svg/users.svg";
import subscribe from "./images/svg/youtuber-4527.svg";
import wallet from "./images/svg/wallet.svg";
import walletBlack from "./images/svg/wallet-black.svg";
import padlock from "./images/svg/padlock.svg";
import padunlock from "./images/svg/padunlock.svg";
import brand from "./images/svg/brand.svg";
import blueeye from "./images/svg/blue-eye.svg";
import closeblack from "./images/svg/close-black.svg";
import ViewIcon from "./images/svg/ViewIcon";
import ModalCloseIcon from "./images/svg/ModalCloseIcon";


const Svg = {
  dashboard,
  userlist,
  subscribe,
  logo,
  very,
  editprofile,
  exportIcon,
  adduser,
  check,
  unverified,
  changepassword,
  addusermodal,
  settings,
  notification,
  search,
  save,
  exporticon,
  filter,
  trash,
  yelloweye,
  plus,
  close,
  blueedit,
  modalclose,
  bergurmenu,
  logout,
  edit,
  upload,
  genres,
  games,
  cms,
  newspaper,
  review,
  network,
  platform,
  status,
  guide,
  role,
  subadmin,
  editorchoice,
  partner,
  team,
  slidergaming,
  slider,
  event,
  copy,
  quest,
  users,
  wallet,
  walletBlack,
  padlock,
  padunlock,
  brand,
  blueeye,
  closeblack,
  ViewIcon,
  ModalCloseIcon,
  copyIcon
};

export default Svg;
