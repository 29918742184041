import React from "react";
import Index from "../../../Index";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import presaleAbi from "./presaleAbi.json";
import { useWeb3ModalSigner } from "@web3modal/ethers5/react";
import PageIndex from "../../../PageIndex";
import { preSaleAddress } from "../../AddressHelper";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "transparent",
};
const PresalePhases = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [assignLoading, setAssignLoading] = useState(false);
  const [functionsTokonomicsData, setFunctionsTokonomicsData] = useState([]);
  const [amount, setAmount] = useState();
  const [address, setAddress] = useState("");
  const [functionPhase, setFunctionPhase] = React.useState();
  const [selectedPhase, setSelectedPhase] = useState("Select Phase");
  const { signer } = useWeb3ModalSigner();
  const writeContract = new ethers.Contract(preSaleAddress, presaleAbi, signer);
  const handleChange = (data) => {
    setSelectedPhase(data.functionName);
    setFunctionPhase(data);
  };
  const validateAddress = (address) => {
    return ethers.utils.isAddress(address);
  };
  const allReadData = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(
        "https://bsc-dataseed1.binance.org/"
      );
      const contract = new ethers.Contract(
        preSaleAddress,
        presaleAbi,
        provider
      );
      const getTokonomicsDetails = await contract.getTokonomicsData();
      const optimizedData = getTokonomicsDetails
        .slice(3, 9)
        .filter(
          (element) =>
            element?.name !== "Treasury" &&
            element?.name !== "Ecosystem" &&
            element?.name !== "PartnerShip/Exchanges"
        )
        .map((element, index) => {
          return {
            functionName: element[0],
            Quantity: element[2].toString() / 10 ** 18,
            totalSupplied: element.totalSuppliedToken?.toString() / 10 ** 18,
            totalPhaseToken: element.totalPhaseToken?.toString() / 10 ** 18,
          };
        });
      setFunctionsTokonomicsData(optimizedData);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const callActionFunction = async (funcName) => {
    try {
      if (funcName === "Public Sale") {
        handleOpen2();
      } else if (funcName === "Founders/Team") {
        handleOpen();
      } else if (funcName === "Advisors") {
        handleOpen();
      }
      allReadData();
    } catch (error) {
      PageIndex.toast.error(error.message);
    }
  };
  const assigningToken = async (funcName) => {
    if (funcName === "Public Sale") {
      if (validateAddress(address)) {
        try {
          setAssignLoading(true);
          const tx = await writeContract.publicSaleToken(address);
          await tx.wait();
          setAssignLoading(false);
          setOpen2(false);
          PageIndex.toast.success("Token Successfully Transferred");
          allReadData();
        } catch (error) {
          PageIndex.toast.error(error?.error?.data?.message);
          setAssignLoading(false);
        }
      } else {
        PageIndex.toast.error("Please Enter Valid Address");
      }
    } else if (funcName === "Founders/Team") {
      const assignToken = ethers.utils.parseEther(amount);
      if (validateAddress(address)) {
        try {
          setAssignLoading(true);
          const tx = await writeContract.addFounderToken(assignToken, address);
          await tx.wait();
          setAssignLoading(false);
          setOpen(false);
          PageIndex.toast.success("Token assigned Successfully");
          allReadData();
        } catch (error) {
          console.error(error);
          setAssignLoading(false);
          PageIndex.toast.error(error?.error?.data?.message);
        }
      } else {
        PageIndex.toast.error("Please Enter Valid Address");
      }
    } else if (funcName === "Advisors") {
      const assignToken = ethers.utils.parseEther(amount);
      if (validateAddress(address)) {
        try {
          setAssignLoading(true);
          const tx = await writeContract.addAdvisorToken(assignToken, address);
          await tx.wait();
          setAssignLoading(false);
          setOpen(false);
          PageIndex.toast.success("Token assigned Successfully");
          allReadData();
        } catch (error) {
          console.error("Error:", error);
          setAssignLoading(false);
          PageIndex.toast.error(error?.error?.data?.message);
        }
      } else {
        PageIndex.toast.error("Please Enter Valid Address");
      }
    }
  };

  useEffect(() => {
    allReadData();
  }, []);
  return (
    <>
      <Index.Box className="presale-wraper">
        <Index.Box className="cus-table offering-list">
          <Index.Box className="cus-table-header d-flex">
            <Index.Box className="cus-th">Phase name</Index.Box>
            <Index.Box className="cus-th">Supplied</Index.Box>
            <Index.Box className="cus-th">Total</Index.Box>
            <Index.Box className="cus-th">Action</Index.Box>
          </Index.Box>
          <Index.Box className="cus-table-body">
            <Index.Box className="cus-tr d-flex">
              <Index.Box className="cus-td">
                <Index.FormControl
                  sx={{ m: 1, minWidth: 120, color: "white", width: "100%" }}
                  size="small"
                  className="cus-select"
                >
                  <Index.Select
                    sx={{ color: "white" }}
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectedPhase}
                    onChange={(e) => setSelectedPhase(e.target.value)}
                  >
                    <Index.MenuItem value="Select Phase" disabled>
                      Select Phase
                    </Index.MenuItem>
                    {functionsTokonomicsData.map((data, index) => (
                      <Index.MenuItem
                        key={data.functionName}
                        value={data?.functionName}
                        onClick={() => handleChange(data)}
                      >
                        {data?.functionName}
                      </Index.MenuItem>
                    ))}
                  </Index.Select>
                </Index.FormControl>
              </Index.Box>
              <Index.Box className="cus-td">
                <Index.Box className="offering-td-box quantity-box gradient-box th-quantity">
                  <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="offering-th-text"
                      sx={{
                        color: "white",
                      }}
                    >
                      {functionPhase
                        ? Number(functionPhase.totalSupplied).toFixed(2)
                        : 0}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="cus-td">
                <Index.Box className="offering-td-box quantity-box gradient-box th-quantity">
                  <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="offering-th-text"
                      sx={{
                        color: "white",
                      }}
                    >
                      {functionPhase
                        ? Number(functionPhase.totalPhaseToken).toFixed(2)
                        : 0}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="cus-td">
                <Index.Button
                  className="offering-td-box quantity-box gradient-box th-quantity"
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    callActionFunction(functionPhase?.functionName)
                  }
                  disabled={!functionPhase}
                >
                  <Index.Button className=" cus-center " disableRipple>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="offering-th-text"
                      sx={{
                        color: "white",
                      }}
                    >
                      Action
                    </Index.Typography>
                  </Index.Button>
                </Index.Button>

                <Index.Modal
                  open={open}
                  onClose={handleClose}
                  className="modal-wrape"
                >
                  <Index.Box sx={style} className="modal-pad">
                    <Index.Box className="crtl-wrape">
                      <Index.Box className="inner-pad2">
                        <Index.Typography className="mint-btn" component="p">
                          Amount
                        </Index.Typography>
                        <Index.TextField
                          variant="standard"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          placeholder="amount to transfer"
                          className="input-fild"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                        ></Index.TextField>
                        <Index.Typography className="mint-btn" component="p">
                          Address
                        </Index.Typography>
                        <Index.TextField
                          variant="standard"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          placeholder="0x..."
                          className="input-fild"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        ></Index.TextField>
                        <div className="custom-btn-group">
                          <Index.PrimaryButton
                            onClick={handleClose}
                            btnLabel="Cancel"
                            className="primary-btn cus-cancel-btn"
                          />
                          {assignLoading ? (
                            <Index.PrimaryButton
                              btnLabel="loading"
                              className="primary-btn"
                              loader={assignLoading}
                            />
                          ) : (
                            <Index.PrimaryButton
                              onClick={() =>
                                assigningToken(functionPhase.functionName)
                              }
                              btnLabel="Assign"
                              className="primary-btn"
                              loader={assignLoading}
                            />
                          )}
                        </div>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Modal>
                <Index.Modal
                  open={open2}
                  onClose={handleClose2}
                  className="modal-wrape"
                >
                  <Index.Box sx={style} className="modal-pad">
                    <Index.Box className="crtl-wrape">
                      <Index.Box className="inner-pad2">
                        <Index.Typography className="mint-btn" component="p">
                          Address
                        </Index.Typography>
                        <Index.TextField
                          variant="standard"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          placeholder="0x..."
                          className="input-fild"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        ></Index.TextField>
                        <div className="custom-btn-group">
                          <Index.PrimaryButton
                            onClick={handleClose2}
                            btnLabel="Cancel"
                            className="primary-btn cus-cancel-btn"
                          />
                          {assignLoading ? (
                            <Index.PrimaryButton
                              btnLabel="loading"
                              className="primary-btn"
                              loader={assignLoading}
                            />
                          ) : (
                            <Index.PrimaryButton
                              onClick={() =>
                                assigningToken(functionPhase.functionName)
                              }
                              btnLabel="Assign"
                              className="primary-btn"
                              loader={assignLoading}
                            />
                          )}
                        </div>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Modal>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default PresalePhases;
