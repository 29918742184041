import React from "react";
import Index from "../../../Index";
import { useEffect, useState } from "react";
import { useWeb3ModalSigner } from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import abi from "./abi.json";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import presaleAbi from "./presaleAbi.json";
import { preSaleAddress, pltToken } from "../../AddressHelper";
import PageIndex from "../../../PageIndex";
import LoadingButton from "@mui/lab/LoadingButton";
import { current } from "@reduxjs/toolkit";
import { Send } from "@mui/icons-material";

const PresaleActivity = () => {
  const [tokensData, setTokensData] = useState({
    currentPhasePrice: 0,
    nextPhasePrice: 0,
    tokenSold: 0,
    totalTokenSoldInPhases: 0,
    privateSalePhaseName: "",
    activePhase: "0",
    presaleIsActive: false,
  });
  const [tokonomicsData, setTokonomicsData] = useState([]);
  const [recepientAddress, setRecepientAddress] = useState("");
  const [dataOracle, setDataOracle] = useState("");
  const [pltTokenAddress, setPltTokenAddress] = useState("");
  const [usdtTokenAddress, setUSDTTokenAddress] = useState("");
  const [recoverAmount, setRecoverAmount] = useState();
  const [minPrice, setMinPrice] = useState();
  const [maxPrice, setMaxPrice] = useState();
  const [recoverAddress, setRecoverAddress] = useState("");
  const [tokenBalance, setTokenBalance] = useState("");
  const { signer } = useWeb3ModalSigner();
  const [phasePrice, setPhasePrice] = useState();
  const phase = ["Start Presale", "Active Phase 2", "Active Phase 3"];
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [functionPhase, setFunctionPhase] = useState("Select Phase");
  const [loading, setLoading] = useState(false);
  const [changeUSDTLoading, setChangeUSDTLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [changeDataOracleLoading, setChangeDataOracleLoading] = useState(false);
  const [changePltAddLoading, setChangePltAddLoading] = useState(false);
  const [changeTimeLoading, setChangeTimeLoading] = useState(false);
  const [changePriceLoading, setChangePriceLoading] = useState(false);
  const [recoverTokenLoading, setRecoverTokenLoading] = useState(false);
  const [privateSaleLoading, setprivateSaleLoading] = useState(false);
  const [selectedPhase, setSelectedPhase] = useState("Select Phase");
  const [closePrivatesaleLoading, setClosePrivatesaleLoading] = useState(false);
  const [activePhaseLoading, setActivePhaseLoading] = useState(false);
  const [givePrivateSaleAmount, setGivePrivateSaleAmount] = useState();
  const [givePrivateSaleAddress, setGivePrivateSaleAddress] = useState();
  const [phaseRemainingToken, setPhaseRemainingToken] = useState(0);

  const handleChange = (data) => {
    setSelectedPhase(data.OfferingType);
  };
  const handlePhaseForPrice = (data) => {
    setFunctionPhase(data.OfferingType);
  };
  const validateAddress = (address) => {
    return ethers.utils.isAddress(address);
  };
  const allReadData = async () => {
    // setPresaleDataFeting(true);
    const provider = new ethers.providers.JsonRpcProvider(
      "https://bsc-dataseed1.binance.org/"
    );
    const contract = new ethers.Contract(preSaleAddress, presaleAbi, provider);
    const plotopeContract = new ethers.Contract(pltToken, abi, provider);
    try {
      const getPhaseDetails = await contract.getPhasePricesAndTokens();
      setTokensData({
        currentPhasePrice: getPhaseDetails[0].toString() / 10000,
        nextPhasePrice: getPhaseDetails[1].toString() / 10000,
        tokenSold: getPhaseDetails[2].toString() / 10 ** 18,
        totalTokenSoldInPhases: getPhaseDetails[3].toString() / 10 ** 18,
        privateSalePhaseName: getPhaseDetails[4],
        activePhase: getPhaseDetails[5].toString(),
        presaleIsActive: getPhaseDetails[6],
      });

      const getTokonomicsDetails = await contract.getTokonomicsData();
      const price = await contract.getPhasePrice();
      const optimizedData = getTokonomicsDetails
        .slice(0, 3)
        .map((element, index) => {
          const totalRaise =
            (element[2].toString() / 10 ** 18) *
            (price[index]?.toNumber() || 0);
          return {
            OfferingType: element[0],
            Quantity: element[2].toString() / 10 ** 18,
            Price: (price[index]?.toNumber() || 0) / 10000,
            Raise: totalRaise / 10000,
          };
        });
      setTokonomicsData(optimizedData);
      const token = await plotopeContract.balanceOf(preSaleAddress);
      setTokenBalance(token.toString() / 10 ** 18);
      // setPresaleDataFeting(false);
      const remainingToken = await contract.remainingPhaseToken();
      console.log(
        remainingToken.toString() / 10 ** 18,
        "remainingToken.toString() / 10 ** 18"
      );
      setPhaseRemainingToken(Number(remainingToken.toString() / 10 ** 18));
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const closePrivaseSaleStatus = async () => {
    const contract = new ethers.Contract(preSaleAddress, presaleAbi, signer);
    try {
      setClosePrivatesaleLoading(true);
      const tx = await contract.closePrivateSale();
      await tx.wait();
      PageIndex.toast.success("Private sale closed successfully");
    } catch (error) {
      console.error("Error:", error);
      PageIndex.toast.error("Private sale close failed");
    } finally {
      setClosePrivatesaleLoading(false);
    }
  };
  const startOrActivePhase = async (index) => {
    if (!signer) {
      PageIndex.toast.error("Please connect wallet first");
    }
    const contract = new ethers.Contract(preSaleAddress, presaleAbi, signer);
    try {
      setActivePhaseLoading(true);
      if (index === 0) {
        const tx = await contract.startPrivateSale();
        await tx.wait();
      } else if (index === 1) {
        const tx = await contract.changePhaseStatus(index);
        await tx.wait();
      } else if (index === 2) {
        const tx = await contract.changePhaseStatus(index);
        await tx.wait();
      }
      allReadData();
      PageIndex.toast.success("Phase changed successfully");
    } catch (error) {
      console.error("Error:", error);
      PageIndex.toast.error(error?.error?.data?.message);
    } finally {
      setActivePhaseLoading(false);
    }
  };
  const changeRecepientAddress = async () => {
    const contract = new ethers.Contract(preSaleAddress, presaleAbi, signer);
    if (validateAddress(recepientAddress)) {
      try {
        setLoading(true);
        const tx = await contract.changeRecipientAddress(recepientAddress);
        await tx.wait();
        PageIndex.toast.success("Recepient address changed successfully");
      } catch (error) {
        console.error("Error:", error);
        PageIndex.toast.error(error?.error?.data?.message);
      } finally {
        setLoading(false);
      }
    } else {
      PageIndex.toast.error("Invalid recepient address");
    }
  };

  const ChangeDataOracle = async () => {
    try {
      const contract = new ethers.Contract(preSaleAddress, presaleAbi, signer);
      if (validateAddress(dataOracle)) {
        try {
          setChangeDataOracleLoading(true);
          const tx = await contract.changeDataOracle(dataOracle);
          await tx.wait();
          setDataOracle("");
          PageIndex.toast.success("DataOracle address changed successfully");
        } catch (error) {
          console.error("Error:", error);
          PageIndex.toast.error(error?.error?.data?.message);
        } finally {
          setChangeDataOracleLoading(false);
        }
      } else {
        PageIndex.toast.error("Invalid DataOracle address");
      }
    } catch (error) {}
  };

  const ChangePLTTokenAddress = async () => {
    try {
      const contract = new ethers.Contract(preSaleAddress, presaleAbi, signer);
      if (validateAddress(pltTokenAddress)) {
        try {
          setChangePltAddLoading(true);
          const tx = await contract.changePLTTokenAddress(pltTokenAddress);
          await tx.wait();
          setPltTokenAddress("");
          PageIndex.toast.success("PLT Token address changed successfully");
        } catch (error) {
          console.error("Error:", error);
          PageIndex.toast.error(error?.error?.data?.message);
        } finally {
          setChangePltAddLoading(false);
        }
      } else {
        PageIndex.toast.error("Invalid PLT Token address");
      }
    } catch (error) {}
  };

  const ChangeUSDTAddress = async () => {
    try {
      const contract = new ethers.Contract(preSaleAddress, presaleAbi, signer);
      if (validateAddress(usdtTokenAddress)) {
        try {
          setChangeUSDTLoading(true);
          const tx = await contract.changeUSDTAddress(usdtTokenAddress);
          await tx.wait();
          setUSDTTokenAddress("");
          PageIndex.toast.success("USDT Token address changed successfully");
        } catch (error) {
          console.error("Error:", error);
          PageIndex.toast.error(error?.error?.data?.message);
        } finally {
          setChangeUSDTLoading(false);
        }
      } else {
        PageIndex.toast.error("Invalid USDT Token address");
      }
    } catch (error) {}
  };

  const ChangeMinPrice = async () => {
    try {
      const contract = new ethers.Contract(preSaleAddress, presaleAbi, signer);
      try {
        setLoading2(true);
        const tx = await contract.changeMinPrice(minPrice);
        await tx.wait();
        PageIndex.toast.success("Min Price changed successfully");
      } catch (error) {
        console.error("Error:", error);
        PageIndex.toast.error(error?.error?.data?.message);
      } finally {
        setLoading2(false);
      }
    } catch (error) {}
  };

  const ChangeMaxPrice = async () => {
    try {
      const contract = new ethers.Contract(preSaleAddress, presaleAbi, signer);
      try {
        setLoading3(true);
        const tx = await contract.changeMaxPrice(maxPrice);
        await tx.wait();
        PageIndex.toast.success("Max Price changed successfully");
      } catch (error) {
        console.error("Error:", error);
        PageIndex.toast.error(error?.error?.data?.message);
      } finally {
        setLoading3(false);
      }
    } catch (error) {}
  };
  const recoverToken = async () => {
    const contract = new ethers.Contract(preSaleAddress, presaleAbi, signer);
    const weiValue = ethers.utils.parseEther(recoverAmount);
    if (validateAddress(recoverAddress)) {
      try {
        setRecoverTokenLoading(true);
        const tx = await contract.recoverTokens(weiValue, recoverAddress);
        await tx.wait();
        PageIndex.toast.success("Token recovered successfully");
      } catch (error) {
        PageIndex.toast.error(error?.error?.data?.message);
      } finally {
        setRecoverTokenLoading(false);
      }
    } else {
      PageIndex.toast.error("Invalid recepient address");
    }
  };
  const changePrivateSaleTime = async (phase, date) => {
    var timestamp = new Date(date).getTime() / 1000;

    const contract = new ethers.Contract(preSaleAddress, presaleAbi, signer);
    if (phase === "Pre Seed") {
      try {
        setChangeTimeLoading(true);
        const tx = await contract.changePrivateSaleTime(0, timestamp);
        await tx.wait();
        PageIndex.toast.success("Time changed successfully");
      } catch (error) {
        PageIndex.toast.error(error?.error?.data?.message);
      } finally {
        setChangeTimeLoading(false);
      }
    } else if (phase === "Seed") {
      try {
        setChangeTimeLoading(true);
        const tx = await contract.changePrivateSaleTime(1, timestamp);
        await tx.wait();
        PageIndex.toast.success("Time changed successfully");
      } catch (error) {
        PageIndex.toast.error(error?.error?.data?.message);
      } finally {
        setChangeTimeLoading(false);
      }
    } else if (phase === "strategic") {
      try {
        setChangeTimeLoading(true);
        const tx = await contract.changePrivateSaleTime(2, timestamp);
        await tx.wait();
        PageIndex.toast.success("Time changed successfully");
      } catch (error) {
        PageIndex.toast.error(error?.error?.data?.message);
      } finally {
        setChangeTimeLoading(false);
      }
    }
  };
  const changePrivateSalePrice = async (phase, price) => {
    const contract = new ethers.Contract(preSaleAddress, presaleAbi, signer);
    if (phase === "Pre Seed") {
      try {
        setChangePriceLoading(true);
        const tx = await contract.changePrivateSalePrice(0, price);
        await tx.wait();
        PageIndex.toast.success("Price changed successfully");
      } catch (error) {
        PageIndex.toast.error(error?.error?.data?.message);
      } finally {
        setChangePriceLoading(false);
      }
    } else if (phase === "Seed") {
      try {
        setChangePriceLoading(true);
        const tx = await contract.changePrivateSalePrice(1, price);
        await tx.wait();
        PageIndex.toast.success("Price changed successfully");
      } catch (error) {
        PageIndex.toast.error(error?.error?.data?.message);
      } finally {
        setChangePriceLoading(false);
      }
    } else if (phase === "strategic") {
      try {
        setChangePriceLoading(true);
        const tx = await contract.changePrivateSalePrice(2, price);
        await tx.wait();
        PageIndex.toast.success("Price changed successfully");
      } catch (error) {
        PageIndex.toast.error(error?.error?.data?.message);
      } finally {
        setChangePriceLoading(false);
      }
    }
  };

  const sendPrivateSaleToken = async () => {
    const contract = new ethers.Contract(preSaleAddress, presaleAbi, signer);
    const weiValue = ethers.utils.parseEther(givePrivateSaleAmount);
    if (validateAddress(givePrivateSaleAddress)) {
      try {
        setprivateSaleLoading(true);
        const tx = await contract.givePrivateSaleToken(
          weiValue,
          givePrivateSaleAddress
        );
        await tx.wait();
        PageIndex.toast.success("Token recovered successfully");
        setGivePrivateSaleAmount("");
        setGivePrivateSaleAddress("");
      } catch (error) {
        PageIndex.toast.error(error?.error?.data?.message);
      } finally {
        setprivateSaleLoading(false);
      }
    } else {
      PageIndex.toast.error("Invalid recepient address");
    }
  };

  useEffect(() => {
    allReadData();
    // recepientAddressData();
  }, []);
  return (
    <>
      <Index.Box className="presale-wraper">
        <Index.Box className="res-cus-table">
          <Index.Box className="offering-cus-table">
            <Index.Box className="offering-table-head">
              <Index.Typography
                component="p"
                variant="p"
                className="offering-th th-blank"
              ></Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="offering-th th-offe-type"
              >
                Offering Type
              </Index.Typography>
              <Index.Box className="offering-list table-head-adjust">
                <Index.Box className="inner-wrape">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="offering-th th-quantity"
                  >
                    Quantity
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="offering-th th-quantity th-offe-price"
                  >
                    Price
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="offering-th th-quantity th-offe-raise"
                  >
                    Raise
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="offering-th th-quantity th-action"
                  >
                    Action
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            {/* {presaleDataFeting ? (
              <Index.Loading  style={{marginLeft: "10px"}}/>
            ) : ( */}
            <Index.Box className="offering-table-body">
              {tokonomicsData.map((data, index) => (
                <Index.Box className="offering-tr">
                  <Index.Box className="offering-tr-main space-between gradient-inner-box">
                    {index <= tokensData.activePhase &&
                    tokensData.presaleIsActive === true ? (
                      <Index.Box className="th-blank">
                        <Index.Box className="lock-main">
                          <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                            <img
                              src={Index.Svg.padunlock}
                              alt="lock"
                              className="lock-ic"
                              style={{
                                color:
                                  data.OfferingType ===
                                    tokensData.privateSalePhaseName &&
                                  tokensData.presaleIsActive === true
                                    ? "lightgreen !important"
                                    : "white",
                              }}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    ) : 2 === parseInt(tokensData.activePhase) &&
                      tokensData.presaleIsActive === false ? (
                      <Index.Box className="th-blank">
                        <Index.Box className="lock-main gradient-box">
                          <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                            <img
                              src={Index.Svg.padunlock}
                              alt="lock"
                              className="lock-ic"
                              style={{
                                color:
                                  data.OfferingType ===
                                    tokensData.privateSalePhaseName &&
                                  tokensData.presaleIsActive === true
                                    ? "lightgreen !important"
                                    : "white",
                              }}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    ) : (
                      <Index.Box className="th-blank">
                        <Index.Box className="lock-main gradient-box">
                          <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                            <img
                              src={Index.Svg.padlock}
                              alt="lock"
                              className="lock-ic"
                              style={{
                                color:
                                  data.OfferingType ===
                                    tokensData.privateSalePhaseName &&
                                  tokensData.presaleIsActive === true
                                    ? "lightgreen !important"
                                    : "white",
                              }}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    )}
                    <Index.Box className="th-offe-type">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="offering-th-text offering-pre-seed"
                        sx={{
                          color:
                            data.OfferingType ===
                              tokensData.privateSalePhaseName &&
                            tokensData.presaleIsActive === true
                              ? "#18BF2E !important "
                              : "white",
                        }}
                      >
                        {data.OfferingType}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="offering-list d-flex">
                      <Index.Box className="inner-wrape">
                        <Index.Box className="offering-td-box quantity-box th-quantity">
                          <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="offering-th-text"
                              sx={{
                                color:
                                  data.OfferingType ===
                                    tokensData.privateSalePhaseName &&
                                  tokensData.presaleIsActive === true
                                    ? "#18BF2E !important "
                                    : "white",
                              }}
                            >
                              {Number(data.Quantity).toFixed(2)}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="offering-td-box quantity-box th-quantity">
                          <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="offering-th-text"
                              sx={{
                                color:
                                  data.OfferingType ===
                                    tokensData.privateSalePhaseName &&
                                  tokensData.presaleIsActive === true
                                    ? "#18BF2E !important "
                                    : "white",
                              }}
                            >
                              ${data.Price}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="offering-td-box quantity-box th-quantity">
                          <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="offering-th-text"
                              sx={{
                                color:
                                  data.OfferingType ===
                                    tokensData.privateSalePhaseName &&
                                  tokensData.presaleIsActive === true
                                    ? "#18BF2E !important "
                                    : "white",
                              }}
                            >
                              ${Number(data.Raise).toFixed(2)}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                        {tokensData.presaleIsActive === false &&
                        parseInt(tokensData.activePhase) === 0 &&
                        parseInt(tokensData.activePhase) === index ? (
                          <Index.Box
                            className="offering-td-box quantity-box th-quantity"
                            sx={{ cursor: "pointer" }}
                            onClick={() => startOrActivePhase(index)}
                          >
                            <Index.Box className="lock-ic-main cus-center ">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="offering-th-text"
                                sx={{
                                  color:
                                    data.OfferingType ===
                                      tokensData.privateSalePhaseName &&
                                    tokensData.presaleIsActive === true
                                      ? "#18BF2E !important "
                                      : "white",
                                }}
                              >
                                {phase[index]}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        ) : tokensData.presaleIsActive === true &&
                          index > parseInt(tokensData.activePhase) ? (
                          <Index.Box
                            className="offering-td-box quantity-box th-quantity"
                            sx={{ cursor: "pointer" }}
                            onClick={() => startOrActivePhase(index)}
                          >
                            <Index.Box className="lock-ic-main cus-center gradient-inner-box btn-like">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="offering-th-text"
                                sx={{
                                  color:
                                    data.OfferingType ===
                                      tokensData.privateSalePhaseName &&
                                    tokensData.presaleIsActive === true
                                      ? "#18BF2E !important "
                                      : "white",
                                }}
                              >
                                {phase[index]}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        ) : index === parseInt(tokensData.activePhase) &&
                          tokensData.presaleIsActive === true ? (
                          <>
                            <Index.Box className="offering-td-box quantity-box th-quantity">
                              <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="offering-th-text"
                                  sx={{
                                    color:
                                      index <= tokensData.activePhase &&
                                      tokensData.presaleIsActive === true
                                        ? "#18BF2E !important "
                                        : "white",
                                  }}
                                >
                                  Ongoing
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </>
                        ) : index < parseInt(tokensData.activePhase) &&
                          tokensData.presaleIsActive === true ? (
                          <>
                            <Index.Box className="offering-td-box quantity-box th-quantity">
                              <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="offering-th-text"
                                  sx={{
                                    color:
                                      index <= tokensData.activePhase &&
                                      tokensData.presaleIsActive === true
                                        ? "white "
                                        : "white",
                                  }}
                                >
                                  Completed
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </>
                        ) : index <= parseInt(tokensData.activePhase) &&
                          tokensData.presaleIsActive === false ? (
                          <>
                            <Index.Box className="offering-td-box quantity-box th-quantity">
                              <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="offering-th-text"
                                  sx={{
                                    color:
                                      index <= tokensData.activePhase &&
                                      tokensData.presaleIsActive === true
                                        ? "white "
                                        : "white",
                                  }}
                                >
                                  Completed
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </>
                        ) : (
                          <>
                            <Index.Box className="offering-td-box quantity-box th-quantity"></Index.Box>
                          </>
                        )}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              ))}
            </Index.Box>
            {/* )} */}
          </Index.Box>
        </Index.Box>
        <Index.Box className="half-section">
          <Index.Grid container spacing={2}>
            <Index.Grid item xs={6}>
              <Index.Box className="crtl-wrape">
                <Index.Box className="inner-pad2">
                  <Index.Typography className="mint-btn" component="p">
                    Change Recepient Address
                  </Index.Typography>
                  {/* <Index.Typography
                    className="mint-btn"
                    component="p"
                    sx={{ fontSize: "13px !important" }}
                  >
                    Current Recepient Address : {currentRecepipient}
                  </Index.Typography> */}
                  <Index.TextField
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    placeholder="0x..."
                    className="input-fild"
                    value={recepientAddress}
                    onChange={(e) => setRecepientAddress(e.target.value)}
                  ></Index.TextField>

                  {loading ? (
                    <Index.PrimaryButton
                      btnLabel="loading"
                      className="primary-btn"
                      loader={loading}
                    />
                  ) : (
                    <Index.PrimaryButton
                      onClick={changeRecepientAddress}
                      btnLabel="Change"
                      className="primary-btn"
                      loader={loading}
                    />
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Grid>

            <Index.Grid item xs={6}>
              <Index.Box className="crtl-wrape">
                <Index.Box className="inner-pad2">
                  <Index.Typography className="mint-btn" component="p">
                    Change Data Oracle Address
                  </Index.Typography>
                  {/* <Index.Typography
                    className="mint-btn"
                    component="p"
                    sx={{ fontSize: "13px !important" }}
                  >
                    Current Recepient Address : {currentRecepipient}
                  </Index.Typography> */}
                  <Index.TextField
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    placeholder="0x..."
                    className="input-fild"
                    value={dataOracle}
                    onChange={(e) => setDataOracle(e.target.value)}
                  ></Index.TextField>

                  {changeDataOracleLoading ? (
                    <Index.PrimaryButton
                      btnLabel="loading"
                      className="primary-btn"
                      loader={changeDataOracleLoading}
                    />
                  ) : (
                    <Index.PrimaryButton
                      onClick={ChangeDataOracle}
                      btnLabel="Change"
                      className="primary-btn"
                      loader={changeDataOracleLoading}
                    />
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Grid>

            <Index.Grid item xs={6}>
              <Index.Box className="crtl-wrape">
                <Index.Box className="inner-pad2">
                  <Index.Typography className="mint-btn" component="p">
                    Change PlutopeToken Address
                  </Index.Typography>
                  {/* <Index.Typography
                    className="mint-btn"
                    component="p"
                    sx={{ fontSize: "13px !important" }}
                  >
                    Current Recepient Address : {currentRecepipient}
                  </Index.Typography> */}
                  <Index.TextField
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    placeholder="0x..."
                    className="input-fild"
                    value={pltTokenAddress}
                    onChange={(e) => setPltTokenAddress(e.target.value)}
                  ></Index.TextField>

                  {changePltAddLoading ? (
                    <Index.PrimaryButton
                      btnLabel="loading"
                      className="primary-btn"
                      loader={changePltAddLoading}
                    />
                  ) : (
                    <Index.PrimaryButton
                      onClick={ChangePLTTokenAddress}
                      btnLabel="Change"
                      className="primary-btn"
                      loader={changePltAddLoading}
                    />
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Grid>

            <Index.Grid item xs={6}>
              <Index.Box className="crtl-wrape">
                <Index.Box className="inner-pad2">
                  <Index.Typography className="mint-btn" component="p">
                    Change USDT Token Address
                  </Index.Typography>
                  {/* <Index.Typography
                    className="mint-btn"
                    component="p"
                    sx={{ fontSize: "13px !important" }}
                  >
                    Current Recepient Address : {currentRecepipient}
                  </Index.Typography> */}
                  <Index.TextField
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    placeholder="0x..."
                    className="input-fild"
                    value={usdtTokenAddress}
                    onChange={(e) => setUSDTTokenAddress(e.target.value)}
                  ></Index.TextField>

                  {changeUSDTLoading ? (
                    <Index.PrimaryButton
                      btnLabel="loading"
                      className="primary-btn"
                      loader={changeUSDTLoading}
                    />
                  ) : (
                    <Index.PrimaryButton
                      onClick={ChangeUSDTAddress}
                      btnLabel="Change"
                      className="primary-btn"
                      loader={changeUSDTLoading}
                    />
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Grid>

            <Index.Grid item xs={6}>
              <Index.Box className="crtl-wrape">
                <Index.Box className="inner-pad2">
                  <Index.Typography className="mint-btn" component="p">
                    Change Min Price
                  </Index.Typography>
                  {/* <Index.Typography
                    className="mint-btn"
                    component="p"
                    sx={{ fontSize: "13px !important" }}
                  >
                    Current Recepient Address : {currentRecepipient}
                  </Index.Typography> */}
                  <Index.TextField
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    placeholder="1"
                    className="input-fild"
                    value={minPrice}
                    onChange={(e) => setMinPrice(e.target.value)}
                  ></Index.TextField>

                  {loading2 ? (
                    <Index.PrimaryButton
                      btnLabel="loading"
                      className="primary-btn"
                      loader={loading2}
                    />
                  ) : (
                    <Index.PrimaryButton
                      onClick={ChangeMinPrice}
                      btnLabel="Change"
                      className="primary-btn"
                      loader={loading2}
                    />
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Grid>

            <Index.Grid item xs={6}>
              <Index.Box className="crtl-wrape">
                <Index.Box className="inner-pad2">
                  <Index.Typography className="mint-btn" component="p">
                    Change Max Price
                  </Index.Typography>
                  {/* <Index.Typography
                    className="mint-btn"
                    component="p"
                    sx={{ fontSize: "13px !important" }}
                  >
                    Current Recepient Address : {currentRecepipient}
                  </Index.Typography> */}
                  <Index.TextField
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    placeholder="4"
                    className="input-fild"
                    value={maxPrice}
                    onChange={(e) => setMaxPrice(e.target.value)}
                  ></Index.TextField>

                  {loading3 ? (
                    <Index.PrimaryButton
                      btnLabel="loading"
                      className="primary-btn"
                      loader={loading3}
                    />
                  ) : (
                    <Index.PrimaryButton
                      onClick={ChangeMaxPrice}
                      btnLabel="Change"
                      className="primary-btn"
                      loader={loading3}
                    />
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Grid>

            <Index.Grid item xs={6}>
              <Index.Box className="crtl-wrape">
                <Index.Box className="inner-pad2">
                  <Index.Typography className="mint-btn" component="p">
                    Change Private Sale Time
                  </Index.Typography>
                  <Index.FormControl
                    sx={{ m: 1, minWidth: 120, color: "white", width: "100%" }}
                    size="small"
                    className="cus-select"
                  >
                    <Index.Select
                      sx={{ color: "white" }}
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={selectedPhase}
                      onChange={(e) => setSelectedPhase(e.target.value)}
                    >
                      <Index.MenuItem value="Select Phase" disabled>
                        Select Phase
                      </Index.MenuItem>
                      {tokonomicsData.map((data) => (
                        <Index.MenuItem
                          key={data.OfferingType}
                          value={data.OfferingType}
                          onClick={() => handleChange(data)}
                        >
                          {data.OfferingType}
                        </Index.MenuItem>
                      ))}
                    </Index.Select>
                  </Index.FormControl>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date"
                      className="date-new"
                      onChange={(e) => setSelectedDate(e.$d)}
                    />
                  </LocalizationProvider>
                  {changeTimeLoading ? (
                    <Index.PrimaryButton
                      btnLabel="loading"
                      className="primary-btn"
                      loader={changeTimeLoading}
                    />
                  ) : (
                    <Index.PrimaryButton
                      onClick={() =>
                        changePrivateSaleTime(selectedPhase, selectedDate)
                      }
                      btnLabel="Change"
                      className="primary-btn"
                      loader={changeTimeLoading}
                    />
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Grid>
            <Index.Grid item xs={6}>
              <Index.Box className="crtl-wrape">
                <Index.Box className="inner-pad2">
                  <Index.Typography className="mint-btn" component="p">
                    Change Private Sale Price
                  </Index.Typography>
                  <Index.FormControl
                    sx={{
                      m: 1,
                      minWidth: 120,
                      color: "white",
                      width: "100%",
                      marginLeft: "0px",
                    }}
                    size="small"
                    className="cus-select"
                  >
                    <Index.Select
                      sx={{ color: "white" }}
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={functionPhase}
                      onChange={(e) => setFunctionPhase(e.target.value)}
                    >
                      <Index.MenuItem value="Select Phase" disabled>
                        Select Phase
                      </Index.MenuItem>
                      {tokonomicsData.map((data) => (
                        <Index.MenuItem
                          key={data.OfferingType}
                          value={data.OfferingType}
                          onClick={() => handlePhaseForPrice(data)}
                        >
                          {data.OfferingType}
                        </Index.MenuItem>
                      ))}
                    </Index.Select>
                  </Index.FormControl>
                  <Index.TextField
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    placeholder="Enter Amount"
                    className="input-fild"
                    value={phasePrice}
                    onChange={(e) => setPhasePrice(e.target.value)}
                  ></Index.TextField>
                  {changePriceLoading ? (
                    <Index.PrimaryButton
                      btnLabel="loading"
                      className="primary-btn"
                      loader={changePriceLoading}
                    />
                  ) : (
                    <Index.PrimaryButton
                      onClick={() =>
                        changePrivateSalePrice(functionPhase, phasePrice)
                      }
                      btnLabel="Change"
                      className="primary-btn"
                      loader={changePriceLoading}
                    />
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Grid>
            <Index.Grid item xs={6}>
              <Index.Box className="crtl-wrape">
                <Index.Box className="inner-pad2">
                  <Index.Typography className="mint-btn" component="p">
                    Recover Tokens
                    <Index.Typography component="span" variant="span">
                      {tokenBalance} PLT{" "}
                    </Index.Typography>
                  </Index.Typography>
                  <Index.TextField
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    placeholder="Enter Amount"
                    className="input-fild"
                    value={recoverAmount}
                    onChange={(e) => setRecoverAmount(e.target.value)}
                  ></Index.TextField>
                  <Index.TextField
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    placeholder="0x..."
                    className="input-fild"
                    value={recoverAddress}
                    onChange={(e) => setRecoverAddress(e.target.value)}
                  ></Index.TextField>
                  {recoverTokenLoading ? (
                    <Index.PrimaryButton
                      btnLabel="loading"
                      className="primary-btn"
                      loader={recoverTokenLoading}
                    />
                  ) : (
                    <Index.PrimaryButton
                      onClick={recoverToken}
                      btnLabel="Recover"
                      className="primary-btn"
                      loader={recoverTokenLoading}
                    />
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Grid>
            <Index.Grid item xs={6}>
              <Index.Box className="crtl-wrape">
                <Index.Box className="inner-pad2">
                  <Index.Typography className="mint-btn" component="p">
                    Give PrivateSale Tokens
                    <Index.Typography component="span" variant="span">
                      {phaseRemainingToken.toFixed(2)} PLT{" "}
                    </Index.Typography>
                  </Index.Typography>
                  <Index.TextField
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    placeholder="Enter Amount"
                    className="input-fild"
                    value={givePrivateSaleAmount}
                    onChange={(e) => setGivePrivateSaleAmount(e.target.value)}
                  ></Index.TextField>
                  <Index.TextField
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    placeholder="0x..."
                    className="input-fild"
                    value={givePrivateSaleAddress}
                    onChange={(e) => setGivePrivateSaleAddress(e.target.value)}
                  ></Index.TextField>
                  {privateSaleLoading ? (
                    <Index.PrimaryButton
                      btnLabel="loading"
                      className="primary-btn"
                      loader={privateSaleLoading}
                    />
                  ) : (
                    <Index.PrimaryButton
                      onClick={sendPrivateSaleToken}
                      btnLabel="Recover"
                      className="primary-btn"
                      loader={privateSaleLoading}
                    />
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Grid>
            <Index.Grid item xs={6}>
              <Index.Box className="crtl-wrape">
                <Index.Box className="inner-pad2">
                  <Index.Typography className="mint-btn" component="p">
                    Close Private Sale
                  </Index.Typography>
                  {closePrivatesaleLoading ? (
                    <Index.PrimaryButton
                      btnLabel="loading"
                      className="primary-btn"
                      loader={closePrivatesaleLoading}
                    />
                  ) : (
                    <Index.PrimaryButton
                      onClick={closePrivaseSaleStatus}
                      btnLabel="Change"
                      className="primary-btn"
                      loader={closePrivatesaleLoading}
                    />
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Grid>
          </Index.Grid>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default PresaleActivity;
