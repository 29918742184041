import React from 'react';
import Index from '../../../container/Index';

export default function PrimaryButton(props) {
  return (
    <>
      <Index.Box className='primary-btn-main' style={{width:'121px'}}>
        <Index.Button 
        btnLabel = {props.btnLabel}
          className={props.className} 
          onClick={props.onClick}
        >
          {props.loader ? (
            // <span className="spinner">loading...</span>
            <Index.CircularProgress color="secondary" size={15} />
            // <Index.Loading />
          ) : (
            props.btnLabel
          )}
        </Index.Button>
      </Index.Box>
    </>
  )
}