import React, { useState } from "react";
import Index from "../../../Index";
import {
  useWeb3ModalAccount,
  useWeb3ModalSigner,
} from "@web3modal/ethers5/react";
import { preSaleAddress } from "../../AddressHelper";
import { ethers } from "ethers";
import preSaleAbi from "../home/presaleAbi.json";
import PageIndex from "../../../PageIndex";
const Exchange = () => {
  const { address } = useWeb3ModalAccount();
  const [exchangeData, setExchangeData] = useState();
  const { signer } = useWeb3ModalSigner();
  const writeContract = new ethers.Contract(preSaleAddress, preSaleAbi, signer);

  const getTime = (time) => {
    const timestamp = time;
    const date = new Date(timestamp * 1000);
    const year = date.getFullYear().toString().slice(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };
  const allReadData = async () => {
    const provider = new ethers.providers.JsonRpcProvider(
      "https://bsc-dataseed1.binance.org/"
    );
    const contract = new ethers.Contract(preSaleAddress, preSaleAbi, provider);
    try {
      const getDetails = await contract.exchangeTokenData(
        "0x71Fa2f55174ADa1d5F5c67FFf02436bA5A9B580C"
      );
      const totalToken = getDetails?.pltToken.toString() / 10 ** 18;
      const remainingTokens = getDetails?.remainingToken.toString() / 10 ** 18;
      const startTime = getDetails?.startTime.toString();
      const vestingDuration = getDetails?.vestingDuration.toString();
      const lastClaimTime = getDetails.lastClaimTime.toString();
      const releaseInterval = getDetails.releaseInterval.toString();
      const initialCliff = getDetails.initialCliff.toString();

      const Data = {
        totalToken: totalToken,
        remainingTokens: remainingTokens,
        startTime: startTime,
        vestingDuration: vestingDuration,
        lastClaimTime: lastClaimTime,
        releaseInterval: releaseInterval,
        tokensPerClaim: totalToken / 12,
        nextClaimTime: lastClaimTime + releaseInterval,
        initialCliff: initialCliff,
      };
      setExchangeData(Data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const claimToken = async () => {
    try {
      const tx = await writeContract.claimExchangeToken();
      await tx.wait();
      PageIndex.toast.success("Transaction is successfully completed");
      allReadData();
    } catch (error) {
      PageIndex.toast.error(error.error.data.message);
    }
  };
  useState(() => {
    allReadData();
  }, []);
  return (
    <>
      <Index.Box className="presale-wraper">
        <Index.Box className="stat-box">
          <Index.Box className="stat">
            <Index.Box className="text-wrape">
              <Index.Typography component="h6">
                {exchangeData ? Number(exchangeData?.totalToken).toFixed(2) : 0}
              </Index.Typography>
              <Index.Typography component="p">Total Token</Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="stat unlock">
            <Index.Box className="text-wrape">
              <Index.Typography component="h6">
                {exchangeData
                  ? Number(exchangeData?.remainingTokens).toFixed(2)
                  : 0}
              </Index.Typography>
              <Index.Typography component="p">Lock Token</Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="stat available">
            <Index.Box className="text-wrape">
              <Index.Typography component="h6">
                {exchangeData?.startTime > 0
                  ? getTime(exchangeData?.startTime)
                  : "-"}
              </Index.Typography>
              <Index.Typography component="p">Vesting Started</Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="cus-table offering-list">
          <Index.Box className="cus-table-header d-flex">
            <Index.Box className="cus-th">No</Index.Box>
            <Index.Box className="cus-th"> Release Tokens</Index.Box>
            <Index.Box className="cus-th">Releasing Date</Index.Box>
            <Index.Box className="cus-th">Status</Index.Box>
            <Index.Box className="cus-th">Action</Index.Box>
          </Index.Box>
          {exchangeData?.totalToken > 0
            ? Array.from({ length: 12 }, (_, index) => {
                const completed =
                  (Number(exchangeData?.lastClaimTime) -
                    (Number(exchangeData?.startTime) +
                      Number(exchangeData?.initialCliff))) /
                  Number(exchangeData?.releaseInterval);
                let available =
                  (Math.floor(Date.now() / 1000) -
                    Number(exchangeData?.lastClaimTime)) /
                    Number(exchangeData?.releaseInterval) +
                  completed;
                const handleClaim = () => {
                  claimToken();
                };
                let statusColor;
                let statusText;
                let status;
                if (index + 1 <= completed) {
                  statusColor = "lightgreen !important"; // Claimed
                  statusText = "Claimed";
                } else if (index + 1 <= available) {
                  statusColor = "blue !important"; // Available
                  statusText = "Available";
                  status = true;
                } else {
                  statusColor = "#d16b11 !important"; // Coming Soon
                  statusText = "Coming Soon";
                }
                return (
                  <Index.Box className="cus-table-body">
                    <Index.Box className="cus-tr d-flex">
                      <Index.Box className="cus-td">
                        <Index.Box className="offering-td-box quantity-box gradient-box th-quantity">
                          <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="offering-th-text"
                              sx={{
                                color: statusColor,
                              }}
                            >
                              {index + 1}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="cus-td">
                        <Index.Box className="offering-td-box quantity-box gradient-box th-quantity">
                          <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="offering-th-text"
                              sx={{
                                color: statusColor,
                              }}
                            >
                              {Number(exchangeData?.tokensPerClaim).toFixed(3)}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="cus-td">
                        <Index.Box className="offering-td-box quantity-box gradient-box th-quantity">
                          <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="offering-th-text"
                              sx={{
                                color: statusColor,
                              }}
                            >
                              {getTime(
                                String(
                                  Number(
                                    Number(exchangeData?.startTime) +
                                      Number(exchangeData?.initialCliff)
                                  ) +
                                    Number(exchangeData?.releaseInterval) *
                                      (index + 1)
                                )
                              )}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="cus-td">
                        <Index.Box className="offering-td-box quantity-box gradient-box th-quantity">
                          <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="offering-th-text"
                              sx={{
                                color: statusColor,
                              }}
                            >
                              {statusText}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="cus-td">
                        <Index.Button
                          className="offering-td-box quantity-box gradient-box th-quantity"
                          sx={{ cursor: "pointer" }}
                          onClick={status ? handleClaim : undefined}
                          disabled={!status}
                        >
                          <Index.Button className=" cus-center " disableRipple>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="offering-th-text"
                              sx={{
                                color: !status ? "grey" : "white",
                              }}
                            >
                              {index + 1 <= completed ? "Claimed" : "CLAIM"}
                            </Index.Typography>
                          </Index.Button>
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                );
              })
            : null}
          {/* {Array.from({ length: 15 }, (_, index) => {
            const available =
              Number(Number(exchangeData?.startTime) + 2538000) +
              Number(exchangeData?.releaseInterval) * index+1;
            const currentTime = Math.floor(Date.now() / 1000);
            const isAvailable = currentTime > available;
            const handleClaim = () => {
              const updatedClaimedStatus = [...claimedStatus];
              updatedClaimedStatus[index] = true;
              setClaimedStatus(updatedClaimedStatus);

              claimToken();
            };
            let statusColor;
            let statusText;
            if (claimedStatus[index]) {
              statusColor = "lightgreen !important"; // Claimed
              statusText = "Claimed";
            } else if (isAvailable) {
              statusColor = "blue !important"; // Available
              statusText = "Available";
            } else {
              statusColor = "#d16b11 !important"; // Coming Soon
              statusText = "Coming Soon";
            }
            return (
              <Index.Box className="cus-table-body">
                <Index.Box className="cus-tr d-flex">
                  <Index.Box className="cus-td">
                    <Index.Box className="offering-td-box quantity-box gradient-box th-quantity">
                      <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="offering-th-text"
                          sx={{
                            color: statusColor,
                          }}
                        >
                          {index + 1}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="cus-td">
                    <Index.Box className="offering-td-box quantity-box gradient-box th-quantity">
                      <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="offering-th-text"
                          sx={{
                            color: statusColor,
                          }}
                        >
                          {Number(exchangeData?.tokensPerClaim).toFixed(3)}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="cus-td">
                    <Index.Box className="offering-td-box quantity-box gradient-box th-quantity">
                      <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="offering-th-text"
                          sx={{
                            color: statusColor,
                          }}
                        >
                          {getTime(
                            String(
                              Number(
                                Number(exchangeData?.startTime) + 2538000
                              ) +
                                Number(exchangeData?.releaseInterval) *
                                  (index + 1)
                            )
                          )}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="cus-td">
                    <Index.Box className="offering-td-box quantity-box gradient-box th-quantity">
                      <Index.Box className="lock-ic-main cus-center gradient-inner-box">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="offering-th-text"
                          sx={{
                            color: statusColor,
                          }}
                        >
                          {statusText}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="cus-td">
                    <Index.Button
                      className="offering-td-box quantity-box gradient-box th-quantity"
                      sx={{ cursor: "pointer" }}
                      onClick={isAvailable ? handleClaim : undefined}
                      disabled={!isAvailable || claimedStatus[index]}
                    >
                      <Index.Button className=" cus-center " disableRipple>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="offering-th-text"
                          sx={{
                            color: !isAvailable ? "grey" : "white",
                          }}
                        >
                          {claimedStatus[index] ? "Claimed" : "CLAIM"}
                        </Index.Typography>
                      </Index.Button>
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            );
          })} */}
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Exchange;
