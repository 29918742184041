import React from "react";
import { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import CommonPagination from "../../../../component/common/PaginationMaster";
export default function ViewReferral() {
    const { id } = Index.useParams();
    const navigate = Index.useNavigate();
    const { state } = Index.useLocation();
    const [loading, setLoading] = useState(false);
    const [referralDetail, setReferralDetails] = useState([]);
    const [TotalUser, setTotalUser] = useState();
    const [TotalTodayReFerralUser, setTotalTodayReFerralUser] = useState();

    // State for pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecordCount, setTotalRecordCount] = useState(0);
    const [paginationPerPage, setPaginationPerPage] = useState(10);
    const [pageDefault, setPageDefault] = useState(null);

    // Delete record state
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [rowId, setRowId] = useState("");

    // State for pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };


    //Get specific details of referral code.
    const fetchReferralDetails = (id) => {
        try {

            let compaignSpecificBaseUrl = `${PageIndex.Api.GET_SPECIFIC_LIST}/${id}`;
            const newParams = new URLSearchParams();
            newParams.append("page", pageDefault !== null ? pageDefault : currentPage);
            newParams.append("size", paginationPerPage);
            compaignSpecificBaseUrl += `?${newParams.toString()}`;

            PageIndex.doGet(compaignSpecificBaseUrl).then((res) => {
                let response = res?.data?.data
                if (res?.status === 200) {
                    let referralListModify = response.ReferralDetails?.referralUsers?.map((item) => {
                        return {
                            _id: item?._id,
                            deviceId: item?.deviceId ? item?.deviceId : '-',
                            deviceType: item?.deviceType == 0 ? 'Android' : item?.deviceType == 1 ? 'IOS' : "-" ?? '-',
                            walletAddress: item?.walletAddress ? item?.walletAddress : '-',
                            referral_code: item?.referral_code ? item?.referral_code : '-',
                            createdAt: item?.createdAt ? Index.moment(item?.createdAt).utcOffset('+05:30').format("DD-MM-YYYY hh:mm A") : "-",
                        };
                    });
                    setCurrentPage(response?.currentPage);
                    setTotalRecordCount(response.ReferralDetails?.totalReferralUsers);
                    setPageDefault(null)
                    setReferralDetails(referralListModify);
                    setTotalUser(response.ReferralDetails?.totalReferralUsers);
                    setTotalTodayReFerralUser(response.ReferralDetails?.totalTodayReferralUsers);
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                } else {
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                }
            });
        } catch (error) { }
    };

    //Page Laod if id exist call fetchReferralDetails
    useEffect(() => {
        if (id) {
            fetchReferralDetails(id);
        }
    }, [id, paginationPerPage, currentPage]);


    return (
        <>
            <Index.DynamicTitle title="presale-wraper" />
            <Index.Box className="presale-wraper">
                <Index.Box className="res-cus-table">
                    <Index.Box className="offering-cus-table">
                        <Index.Box className="offering-table-head tryed">
                            <Index.Box className="title-main">
                                <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="page-title"
                                >
                                    {state?.name} Referral List
                                </Index.Typography>
                            </Index.Box>

                            <Index.Box className="common-button blue-button save-btn primary-btn-main">
                                <Index.Button
                                    variant="contained"
                                    type="submit"
                                    className="Particular_model_submit ml-0 primary-btn"
                                    onClick={() => navigate("/admin/campaign-master")}
                                >
                                    Back
                                </Index.Button>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                    {/* First Row */}
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                            display="grid"
                            className="display-row-dashboard"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                        >
                            {/* Total Wallets */}
                            <Index.Box
                                gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 6",
                                    lg: "span 3",
                                }}
                                className="grid-column active"
                            >
                                <Index.Box className="admin-dashboard-box">
                                    <Index.Box className="admin-dashboard-inner-box">
                                        <Index.Box className="admin-dash-left">
                                            <Index.Typography
                                                className="admin-dash-text"
                                                variant="p"
                                                component="p"
                                            >
                                                Total Wallets{" "}
                                            </Index.Typography>
                                            <Index.Typography
                                                className="admin-dash-price"
                                                variant="h1"
                                                component="h1"
                                            >
                                                <Index.CountUp
                                                    delay={0.4}
                                                    end={TotalUser}
                                                    duration={0.7}
                                                />
                                            </Index.Typography>
                                        </Index.Box>
                                        <Index.Box className="admin-dash-right">
                                            <img
                                                src={Index.Svg.walletBlack}
                                                className="dash-icons"
                                                alt="dashboard icon"
                                            />
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                            {/* Total Created Wallets */}
                            <Index.Box
                                gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 6",
                                    lg: "span 3",
                                }}
                                className="grid-column total"
                            >
                                <Index.Box className="admin-dashboard-box">
                                    <Index.Box className="admin-dashboard-inner-box">
                                        <Index.Box className="admin-dash-left">
                                            <Index.Typography
                                                className="admin-dash-text"
                                                variant="p"
                                                component="p"
                                            >
                                                Today Created Walllet
                                            </Index.Typography>
                                            <Index.Typography
                                                className="admin-dash-price"
                                                variant="h1"
                                                component="h1"
                                            >
                                                <Index.CountUp
                                                    delay={0.4}
                                                    end={TotalTodayReFerralUser}
                                                    duration={0.7}
                                                />
                                            </Index.Typography>
                                        </Index.Box>
                                        <Index.Box className="admin-dash-right">
                                            <img
                                                src={Index.Svg.wallet}
                                                className="dash-icons"
                                                alt="dashboard icon"
                                            />
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>

                    <Index.Box className="border-round-table">
                        <Index.TableContainer
                            component={Index.Paper}
                            className="table-container"
                        >
                            <Index.Table
                                aria-label="simple table"
                                className="table-design-main supply-table one-line-table invoice-table-set user-management-table trader-list "
                            >
                                <Index.TableHead className="gradient-bg">
                                    <Index.TableRow>
                                        <Index.TableCell align="left">Device Id</Index.TableCell>
                                        <Index.TableCell align="left">Device Type</Index.TableCell>
                                        <Index.TableCell align="left">Wallet Address</Index.TableCell>
                                        <Index.TableCell align="left"> Date </Index.TableCell>
                                    </Index.TableRow>
                                </Index.TableHead>
                                {loading ? (
                                    //Loading Component.
                                    <Index.Loading />
                                ) : (
                                    <Index.TableBody>
                                        {referralDetail?.length ? (
                                            referralDetail.map((item, index) => (
                                                <Index.TableRow key={item?._id}>
                                                    <Index.TableCell>{item?.deviceId}</Index.TableCell>
                                                    <Index.TableCell>{item?.deviceType}</Index.TableCell>
                                                    <Index.TableCell>{item?.walletAddress}</Index.TableCell>
                                                    <Index.TableCell>{item?.createdAt}</Index.TableCell>
                                                </Index.TableRow>
                                            ))
                                        ) : (
                                            // No data found comman componets.
                                            <Index.NoDataFound />
                                        )}
                                    </Index.TableBody>
                                )}
                            </Index.Table>
                        </Index.TableContainer>
                    </Index.Box>
                    {/* //Paginations componets */}
                    <CommonPagination
                        paginationPerPage={paginationPerPage}
                        setPaginationPerPage={setPaginationPerPage}
                        setPageDefault={setPageDefault}
                        totalRecordCount={totalRecordCount}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </Index.Box>
            </Index.Box>
        </>
    );
}
