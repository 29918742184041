import { useState } from "react";
import Index from "../../Index";

import PageIndex from "../../PageIndex";
import { adminForgotPassword } from "../../../redux/slices/AdminService";

export default function ForgotPassword() {
  const [isDisabled, setIsDisabled] = useState(false);
  const dispatch = PageIndex.useDispatch();
  const navigate = PageIndex.useNavigate();

  const submitForm = (values, action) => {
    try {
      setIsDisabled(true);
      dispatch(adminForgotPassword(values)).then((res) => {
        action.resetForm();
        setIsDisabled(false);
        if (res?.payload?.status === 200) {
          navigate("/admin/otp", {
            state: { row: res?.payload },
          });
        }
      });
    } catch (error) {
      setIsDisabled(false);
    }
  };

  return (
    <Index.Box className="admin-login-main-flex">
      <Index.Box className="admin-login-right-main">
        <Index.Box className="admin-login-box">
          <Index.Box className="admin-login-main">
            <Index.Box className="auth-logo-box">
              <img src={Index.Svg.brand} className="auth-logo" />
            </Index.Box>
            <PageIndex.Formik
              validationSchema={PageIndex.adminForgotPasswordSchema}
              initialValues={{
                email: "",
              }}
              onSubmit={submitForm}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
                touched,
                handleBlur,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="admin-login-inner">
                    <Index.Typography
                      component="h2"
                      variant="h2"
                      className="admin-wel-text"
                    >
                      Forgot Password
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="admin-sign-para common-para"
                    >
                      Please enter your email for verification via OTP
                    </Index.Typography>
                    <Index.Box className="input-box">
                      <Index.FormHelperText className="form-lable">
                        Email
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          placeholder="Enter the email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={touched.email && errors.email}
                          error={Boolean(errors.email && touched.email)}
                          fullWidth
                          name="email"
                          id="fullWidth"
                          className="form-control"
                          type="text"
                          autocomplete="off"
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="forgot-pass-main">
                      <Index.Box className="primary-btn-main">
                        <Index.Button
                          type="submit"
                          className="primary-btn"
                          disabled={isDisabled}
                        >
                          Sign In
                        </Index.Button>
                      </Index.Box>
                      <Index.Link className="btn-text" to="/admin/login">
                        Back to login
                      </Index.Link>
                    </Index.Box>
                  </Index.Box>
                </form>
              )}
            </PageIndex.Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
