import { createAsyncThunk } from "@reduxjs/toolkit";
import PageIndex from "../../container/PageIndex";

export const loginAdmin = createAsyncThunk(
  "admin/forget-password",
  async (data) => {
    try {
      const response = await PageIndex.doPost(PageIndex.Api.adminLogin, data);
      return response?.data;
    } catch (error) {}
  }
);

export const adminForgotPassword = createAsyncThunk(
  "admin/forget-password",
  async (data) => {
    try {
      const response = await PageIndex.doPost(
        PageIndex.Api.ADMIN_FORGOT_PASSWORD,
        data
      );
      return response?.data;
    } catch (error) {}
  }
);

export const adminResetPassword = createAsyncThunk(
  "admin/reset-password",
  async (data) => {
    try {
      const response = await PageIndex.doPost(
        PageIndex.Api.ADMIN_RESET_PASSWORD,
        data
      );
      return response?.data;
    } catch (error) {}
  }
);
export const adminVerifyOTP = createAsyncThunk(
  "admin/verify-otp",
  async (data) => {
    try {
      const response = await PageIndex.doPost(
        PageIndex.Api.ADMIN_VERIFY_OTP,
        data
      );
      return response?.data;
    } catch (error) {}
  }
);

export const adminResendOTP = createAsyncThunk(
  "admin/resend-otp",
  async (data) => {
    try {
      const response = await PageIndex.doPost(
        PageIndex.Api.ADMIN_RESEND_OTP,
        data
      );
      return response?.data;
    } catch (error) {}
  }
);

export const getDashboardData = createAsyncThunk(
  "admin/getDashboardData",
  async () => {
    try {
      const response = await PageIndex.doGet(PageIndex.Api.dashboardData);
      return response?.data;
    } catch (error) {}
  }
);

export const getRolePermission = createAsyncThunk(
  "admin/get-user-details",
  async () => {
    try {
      const response = await PageIndex.doGet(PageIndex.Api.GET_ROLE_PERMISSION);
      return response?.data;
    } catch (error) {}
  }
);

// api call for statistic data fetching
export const getStatisticData = async (data) => {
  try {
    const response = await PageIndex.doPost2(PageIndex.Api.statisticData, data);
    // console.log(response?.data, "statistic data");
    return response?.data;
  } catch (error) {}
};

// api call for getDateFilterGraphData data fetching
export const getDateFilterGraphData = async (finalDate) => {
  try {
    const urlencoded = new URLSearchParams();
    urlencoded.append("fromDate", finalDate.fromDate);
    urlencoded.append("toDate", finalDate.toDate);
    const response = await PageIndex.doPost2(
      PageIndex.Api.DATE_BAR_GRAPH,
      urlencoded
    );
    return response?.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const addEditCoin = createAsyncThunk(
  "admin/add-edit-coin",
  async (data) => {
    try {
      const response = await PageIndex.doPost(
        PageIndex.Api.ADD_EDIT_COIN,
        data
      );
      return response?.data;
    } catch (error) {}
  }
);
