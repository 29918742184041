import {
  Box,
  TextField,
  InputLabel,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextareaAutosize,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  DialogActions,
  DialogContent,
  Switch,
  DialogContentText,
  FormHelperText,
  DialogTitle,
  Dialog,
  styled,
  Modal,
  Menu,
  Collapse,
  Stack,
  Pagination,
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link } from "react-router-dom";

import Svg from "../assets/Svg";
import Png from "../assets/Png";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import {
  ManageAccountsOutlined,
  ScatterPlotOutlined,
} from "@mui/icons-material";

const Toast = (message) => {
  toast("Wow so easy!");
  return <ToastContainer />;
};

export default {
  Box,
  useSelector,
  Link,
  Dialog,
  TextField,
  Typography,
  InputLabel,
  FormHelperText,
  DialogContentText,
  FormControlLabel,
  Checkbox,
  DialogActions,
  Button,
  IconButton,
  DialogContent,
  InputAdornment,
  OutlinedInput,
  VisibilityOff,
  Visibility,
  List,
  DialogTitle,
  ListItem,
  Svg,
  Png,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,

  TextareaAutosize,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Modal,
  Menu,
  ExpandLess,
  ExpandMore,
  Collapse,
  Stack,
  Pagination,

  Toast,
  ManageAccountsOutlined,
  ScatterPlotOutlined,
};
