export default [
  { title: "User Management", tag: "UserManagement" },
  { title: "Coin Management", tag: "CoinManagement" },
  { title: "Campaign Management", tag: "CampaignManagement" },
  { title: "Wallet Activity Logs", tag: "WalletActivityLogs" },
  {
    title: "Wallet Creating Activity Logs",
    tag: "WalletCreatingActivityLogs",
  },
];
