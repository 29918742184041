import usericon from "./images/png/user.png";
import loginbg from "./images/png/login-bg.png";
import userlist1 from "./images/png/userlist1.png";
import bergurmenu from "./images/png/bergur-menu.png";
import graph1 from "./images/png/graph1.png";
import dashboadgraph from "./images/png/dashboard-graph.png";
import logo from "./images/png/logo.png";
import Backarrow from "../assets/images/png/backarrow.png";
import News from "../assets/images/png/News.png";
import Slider from "../assets/images/png/slider.png";
import loginImage from "../assets/images/png/login-bg.avif";

import Guide from "../assets/images/png/guide1.png";
import search from "../assets/images/png/search.png";

import Role from "../assets/images/png/roleicons.png";
import Edit from "../assets/images/png/edit.png";
import Edit1 from "../assets/images/png/edit1.png";
import Editor from "../assets/images/png/editchoice1.png";
import Verfiy from "../assets/images/png/verifyicon.png";
import addroleimg from "../assets/images/png/add-role-img.png";
import minus from "../assets/images/png/minus.png";

import iosappstore from "../assets/images/png/ios-app-store.png";
import androidappstore from "../assets/images/png/android-app-store.png";
import plutopeIcon from "../assets/images/png/plutope_logo_circle_black.png";

const Png = {
  iosappstore,
  androidappstore,
  usericon,
  loginbg,
  userlist1,
  bergurmenu,
  loginImage,
  graph1,
  dashboadgraph,
  logo,
  Backarrow,
  News,
  Slider,
  Guide,
  Verfiy,
  Role,
  Edit,
  Edit1,
  Editor,
  addroleimg,
  minus,
  plutopeIcon,
  search,
};

export default Png;
