import React from "react";
import Index from "../../container/Index";
export default function NoDataFound() {
    return (
        <>
            <Index.TableRow>
                <Index.TableCell
                    component="td"
                    variant="td"
                    scope="row"
                    className="no-data-in-list"
                    colSpan={15}
                    align="center"
                >
                    No data available
                </Index.TableCell>
            </Index.TableRow>
        </>
    )
}