import { useState } from "react";
import Index from "../../Index";

import PageIndex from "../../PageIndex";
import { adminResetPassword } from "../../../redux/slices/AdminService";

export default function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);
  const dispatch = PageIndex.useDispatch();
  const navigate = PageIndex.useNavigate();
  const location = Index.useLocation();
  const userId = location?.state?.userId;
  const handleClickShowPassword = (e) => {
    e.preventDefault();
    setShowPassword((show) => !show);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = (e) => {
    e.preventDefault();
    setShowConfirmPassword((show) => !show);
  };

  const submitForm = (values, action) => {
    const data = new URLSearchParams();
    data.append("password", values?.password);
    data.append("confirmPassword", values?.confirmPassword);
    data.append("id", userId);

    try {
      setIsDisabled(true);
      dispatch(adminResetPassword(data)).then((res) => {
        action.resetForm();
        setIsDisabled(false);
        if (res?.payload?.status === 200) {
          navigate("/admin/login");
        }
      });
    } catch (error) {
      setIsDisabled(false);
    }
  };

  return (
    <Index.Box className="admin-login-main-flex">
      <Index.Box className="admin-login-right-main">
        <Index.Box className="admin-login-box">
          <Index.Box className="admin-login-main">
            <Index.Box className="auth-logo-box">
              <img src={Index.Svg.brand} className="auth-logo" />
            </Index.Box>
            <PageIndex.Formik
              validationSchema={PageIndex.resetPasswordValidationSchema}
              initialValues={{
                password: "",
                confirmPassword: "",
              }}
              onSubmit={submitForm}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,

                touched,
                handleBlur,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="admin-login-inner">
                    <Index.Typography
                      component="h2"
                      variant="h2"
                      className="admin-wel-text"
                    >
                      Reset Password
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="admin-sign-para common-para"
                    >
                      In order to keep your account safe you need to create a
                      strong password
                    </Index.Typography>
                    <Index.Box className="input-box">
                      <Index.FormHelperText className="form-lable">
                        New Password
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          size="small"
                          name="password"
                          placeholder="Enter the password"
                          className="form-control input-with-radius password-form-control"
                          type={showPassword ? "text" : "password"}
                          variant="outlined"
                          autoComplete="off"
                          inputProps={{
                            className: "input_props",
                          }}
                          InputLabelProps={{ className: "add-formlabel" }}
                          FormHelperTextProps={{
                            className: "input_label_props",
                          }}
                          onBlur={handleBlur}
                          value={values.password}
                          onChange={handleChange}
                          helperText={touched.password && errors.password}
                          error={Boolean(errors.password && touched.password)}
                          InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                              <Index.InputAdornment position="end">
                                <Index.IconButton
                                  className="passwrd-eye"
                                  aria-label="toggle password visibility"
                                  onClick={(e) => handleClickShowPassword(e)}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            ),
                          }}
                        />
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="input-box">
                      <Index.FormHelperText className="form-lable">
                        Confirm Password
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          size="small"
                          name="confirmPassword"
                          placeholder="Enter the password"
                          className="form-control input-with-radius password-form-control"
                          type={showConfirmPassword ? "text" : "password"}
                          // label="Password"
                          variant="outlined"
                          autoComplete="off"
                          inputProps={{
                            className: "input_props",
                          }}
                          InputLabelProps={{ className: "add-formlabel" }}
                          FormHelperTextProps={{
                            className: "input_label_props",
                          }}
                          onBlur={handleBlur}
                          value={values.confirmPassword}
                          onChange={handleChange}
                          helperText={
                            touched.confirmPassword && errors.confirmPassword
                          }
                          error={Boolean(
                            errors.confirmPassword && touched.confirmPassword
                          )}
                          InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                              <Index.InputAdornment position="end">
                                <Index.IconButton
                                  className="passwrd-eye"
                                  aria-label="toggle password visibility"
                                  onClick={(e) =>
                                    handleClickShowConfirmPassword(e)
                                  }
                                  onMouseDown={handleMouseDownConfirmPassword}
                                  edge="end"
                                >
                                  {showConfirmPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            ),
                          }}
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="forgot-pass-main">
                      <Index.Box className="primary-btn-main">
                        <Index.Button
                          type="submit"
                          className="primary-btn"
                          disabled={isDisabled}
                        >
                          Submit
                        </Index.Button>
                      </Index.Box>
                      <Index.Link className="btn-text" to="/admin/login">
                        Back to login
                      </Index.Link>
                    </Index.Box>
                  </Index.Box>
                </form>
              )}
            </PageIndex.Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
