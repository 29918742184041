export const Api = {
  adminLogin: "admin/admin-login",
  dashboardData: "admin/find-wallets",
  statisticData: "admin/wallet-chart",
  ADMIN_FORGOT_PASSWORD: "admin/forget-password",
  ADMIN_RESET_PASSWORD: "admin/reset-password",
  ADMIN_VERIFY_OTP: "admin/verify-otp",
  ADMIN_RESEND_OTP: "admin/resend-otp",
  DATE_BAR_GRAPH: "admin/date-wallet-chart",
  GET_ROLE_PERMISSION: "admin/get-user-details",
  ADD_EDIT_COMPAIN: "admin/add-edit-campaign",
  GET_COMPAIN_LIST: "admin/get-campaign",
  GET_WALLET_ACTIVITY_LIST: "admin/get-wallet-activity-log",
  GET_WALLET_CREATING_ACTIVITY_LIST: "admin/get-wallet-creating-log",
  GET_EXPORT_CSV: "admin/export-csv",
  GET_SPECIFIC_LIST: "admin/get-specific-campaign-new",
  ADD_EDIT_USER: "admin/add-edit-user",
  GET_ALL_USER: "admin/get-all-user",
  DELETE_USER: "admin/delete-user",
  USER_ACTIVE_DEACTIVE: "admin/user-active-deactive",
  GET_ALL_ROLE_LIST: "admin/get-all-role",
  ADD_EDIT_ROLE: "admin/add-role-edit",
  DELETE_ROLE: "admin/remove-role",
  ADD_EDIT_COIN: "admin/add-edit-coin",
  GET_ALL_COIN: "admin/get-all-coin-list",
  DELETE_COIN: "admin/delete-coin",
};
