import React, { useState } from "react";
import Index from "../../../Index";
import Foundary from "./Foundary";
import Advisor from "./Advisor";
import PrivateSale from "./PrivateSale";
import Ecosystem from "./Ecosystem";
import Exchange from "./Exchange";
import Treasury from "./Treasury";

const History = () => {
  const [activeTab, setActiveTab] = useState(null);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Index.Box className="presale-wraper">
      <Index.Box className="history-custom-btn-group">
        <Index.Box className="history-btn" sx={{background : activeTab === "privateSale"? "linear-gradient(99deg, #4d5e78 0%, #3557c7 100%)":"linear-gradient(99deg, #2b5af3 0%,  #38ff8e 100%" }} onClick={() => handleTabClick("privateSale")}>
          Private Sale
        </Index.Box>

        <Index.Box className="history-btn" sx={{background : activeTab === "foundersTeam"? "linear-gradient(99deg, #4d5e78 0%, #3557c7 100%)":"linear-gradient(99deg, #2b5af3 0%,  #38ff8e 100%" }} onClick={() => handleTabClick("foundersTeam")}>
          Founders And Team
        </Index.Box>
        <Index.Box className="history-btn" sx={{background : activeTab === "advisors"? "linear-gradient(99deg, #4d5e78 0%, #3557c7 100%)":"linear-gradient(99deg, #2b5af3 0%,  #38ff8e 100%" }} onClick={() => handleTabClick("advisors")}>
          Advisors
        </Index.Box>
        <Index.Box className="history-btn" sx={{background : activeTab === "Ecosystem"? "linear-gradient(99deg, #4d5e78 0%, #3557c7 100%)":"linear-gradient(99deg, #2b5af3 0%,  #38ff8e 100%" }} onClick={() => handleTabClick("Ecosystem")}>
          Ecosystem
        </Index.Box>
        <Index.Box className="history-btn" sx={{background : activeTab === "Exchange"? "linear-gradient(99deg, #4d5e78 0%, #3557c7 100%)":"linear-gradient(99deg, #2b5af3 0%,  #38ff8e 100%" }} onClick={() => handleTabClick("Exchange")}>
          Exchange
        </Index.Box>
        <Index.Box className="history-btn" sx={{background : activeTab === "Treasury"? "linear-gradient(99deg, #4d5e78 0%, #3557c7 100%)":"linear-gradient(99deg, #2b5af3 0%,  #38ff8e 100%" }} onClick={() => handleTabClick("Treasury")}>
          Treasury 
        </Index.Box>
      </Index.Box>
      <Index.Box>
      {!activeTab && <PrivateSale />}
        {activeTab === "privateSale" && <PrivateSale />}
        {activeTab === "foundersTeam" && <Foundary />}
        {activeTab === "advisors" && <Advisor />}
        {activeTab === "Ecosystem" && <Ecosystem />}
        {activeTab === "Exchange" && <Exchange />}
        {activeTab === "Treasury" && <Treasury />}
        
      </Index.Box>
    </Index.Box>
  );
};

export default History;
