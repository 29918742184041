import { useState } from "react";
import Index from "../../../Index";
import { rolePermissionSchema } from "../../../../validations/Schema";
import permissionData from "../../../../config/PermissionConfig";

import PageIndex from "../../../PageIndex";

function AddRole() {
  const navigate = Index.useNavigate();
  const location = Index.useLocation();
  const editData = location?.state?.data;
  const { id } = Index.useParams();
  const [loading, setLoading] = useState(false);

  const getInitialValues = (state, sections) => {
    const permissions = ["Add", "Edit", "Delete", "View"];
    return {
      roleName: state ? state.role : "",
      permission: sections.map((section) => ({
        title: section.title,
        tag: section.tag,
        All: permissions?.every(
          (permission) =>
            state?.permissions?.[section.tag]?.[permission] === true
        ),
        ...permissions?.reduce((acc, permission) => {
          acc[permission] =
            state?.permissions?.[section.tag]?.[permission] === true;
          return acc;
        }, {}),
      })),
    };
  };
  const initialValues = getInitialValues(editData, permissionData);

  const handleformSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);
    const cleanedPermissions = values.permission.map(
      ({ tag, title, All, ...rest }) => {
        const permissions = Object.keys(rest).reduce((acc, key) => {
          acc[key] = rest[key] === true ? true : false;
          return acc;
        }, {});

        return { ...permissions };
      }
    );

    const role = {};
    cleanedPermissions.forEach((ele, index) => {
      role[values.permission[index].tag] = ele;
    });
    PageIndex.doPost(
      PageIndex.Api.ADD_EDIT_ROLE,
      {
        role: values.roleName,
        permissions: role,
        id: editData?._id ? editData?._id : "",
      },
      "application/json"
    ).then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        navigate("/admin/role");
      } else {
        setTimeout(() => {
          setSubmitting(false);
        }, 2000);
      }
    });
  };

  const handleAllChange = (e, i, setFieldValue) => {
    const isChecked = e.target.checked;
    setFieldValue(`permission[${i}].All`, isChecked);
    setFieldValue(`permission[${i}].View`, isChecked);
    setFieldValue(`permission[${i}].Add`, isChecked);
    setFieldValue(`permission[${i}].Edit`, isChecked);
    if (permissionData[i].tag !== "CampaignManagement") {
      setFieldValue(`permission[${i}].Delete`, isChecked);
    }
  };

  // const handleAllChange = (e, i, setFieldValue) => {
  //   const isChecked = e.target.checked;
  //   setFieldValue(`permission[${i}].All`, isChecked);
  //   // Set other checkboxes to the same value as "All"
  //   setFieldValue(`permission[${i}].View`, isChecked);
  //   setFieldValue(`permission[${i}].Add`, isChecked);
  //   setFieldValue(`permission[${i}].Edit`, isChecked);
  //   // Only update the "Delete" checkbox if it's not disabled
  //   if (permissionData[i].tag !== "CampaignManagement") {
  //     setFieldValue(`permission[${i}].Delete`, isChecked);
  //   }
  // };

  // old code const handlePermissionChange = (e, i, setFieldValue, type, values) => {
  //   const isChecked = e.target.checked;
  //   setFieldValue(`permission[${i}].${type}`, isChecked);

  //   if (isChecked && type !== "View") {
  //     setFieldValue(`permission[${i}].View`, true);
  //   }

  //   const currentPermissions = {
  //     ...values.permission[i],
  //     [type]: isChecked,
  //   };

  //   const allChecked = ["View", "Add", "Edit", "Delete"].every(
  //     (perm) => currentPermissions[perm]
  //   );
  //   console.log(allChecked, "allChecked");

  //   setFieldValue(`permission[${i}].All`, allChecked);
  // };
  // const handlePermissionChange = (e, i, setFieldValue, type, values) => {
  //   const isChecked = e.target.checked;
  //   setFieldValue(`permission[${i}].${type}`, isChecked);

  //   if (type === "View" && !isChecked) {
  //     // If "View" is unchecked, uncheck all other permissions and "All"
  //     setFieldValue(`permission[${i}].All`, false);
  //     setFieldValue(`permission[${i}].Add`, false);
  //     setFieldValue(`permission[${i}].Edit`, false);
  //     setFieldValue(`permission[${i}].Delete`, false);
  //   } else if (isChecked && type !== "View") {
  //     // If any permission is checked, ensure "View" is also checked
  //     setFieldValue(`permission[${i}].View`, true);
  //   }

  //   const currentPermissions = {
  //     ...values.permission[i],
  //     [type]: isChecked,
  //   };

  //   // Check if "All" should be checked based on remaining permissions
  //   const allChecked = ["View", "Add", "Edit", "Delete"].every(
  //     (perm) => currentPermissions[perm]
  //   );

  //   setFieldValue(`permission[${i}].All`, allChecked);
  // };
  const handlePermissionChange = (e, i, setFieldValue, type, values) => {
    const isChecked = e.target.checked;

    // Update the specific permission type
    setFieldValue(`permission[${i}].${type}`, isChecked);

    // If "View" is unchecked, uncheck all other permissions and "All"
    if (type === "View" && !isChecked) {
      setFieldValue(`permission[${i}].All`, false);
      setFieldValue(`permission[${i}].Add`, false);
      setFieldValue(`permission[${i}].Edit`, false);
      setFieldValue(`permission[${i}].Delete`, false);
    } else if (isChecked && type !== "View") {
      // If any other permission is checked, ensure "View" is also checked
      setFieldValue(`permission[${i}].View`, true);
    }

    // Get current permissions for this row
    const currentPermissions = {
      ...values.permission[i],
      [type]: isChecked,
    };

    // Determine if "All" should be checked based on remaining permissions
    const permissionsToCheck = ["View", "Add", "Edit"];
    if (permissionData[i].tag !== "CampaignManagement") {
      permissionsToCheck.push("Delete");
    }

    const allChecked = permissionsToCheck.every((perm) => {
      console.log(perm, 174);
      return currentPermissions[perm];
    });

    // Update "All" based on whether all permissions are checked
    setFieldValue(`permission[${i}].All`, allChecked);
  };

  const BackBtn = () => {
    navigate("/admin/role");
  };

  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          {loading && id ? (
            <Index.Box className="">
              <Index.Grid container spacing={2}>
                <Index.Grid item xs={5} sm={5} md={5}></Index.Grid>
                <Index.Grid item xs={2} sm={2} md={2}>
                  <Index.CircularProgress color="secondary" size={20} />
                </Index.Grid>
                <Index.Grid item xs={5} sm={5} md={5}></Index.Grid>
              </Index.Grid>
            </Index.Box>
          ) : (
            <>
              <Index.Box className="presale-wraper">
                <Index.Box className="title-header">
                  <Index.Box className="title-header-flex">
                    <Index.Box className="title-main mb-10">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        {editData ? "Edit" : "Add"} Role
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="common-button blue-button save-btn primary-btn-main">
                      <Index.Button
                        variant="contained"
                        type="submit"
                        className="Particular_model_submit ml-0 primary-btn"
                        onClick={BackBtn}
                      >
                        Back
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="crtl-wrape">
                  <Index.Box className="form-wrape">
                    <Index.Formik
                      onSubmit={handleformSubmit}
                      initialValues={initialValues}
                      validationSchema={rolePermissionSchema}
                    >
                      {({
                        values,
                        handleChange,
                        touched,
                        handleSubmit,
                        handleBlur,
                        setFieldValue,
                        isSubmitting,
                        errors,
                      }) => (
                        <form
                          noValidate
                          autoComplete="off"
                          onSubmit={handleSubmit}
                        >
                          <Index.Box className="barge-common-box common-pd-main">
                            <Index.Box className="title-header">
                              <Index.Box className="report-main-box">
                                <Index.Grid container spacing={2}>
                                  <Index.Grid item xs={12} sm={6} md={6}>
                                    <Index.Box className="inner-pad23">
                                      <Index.Typography
                                        className="admin-form-lable"
                                        component="p"
                                      >
                                        Role Name
                                        <span className="astrick-sing">*</span>
                                      </Index.Typography>
                                      <Index.TextField
                                        hiddenLabel
                                        id="filled-hidden-label-normal"
                                        placeholder="Role name"
                                        className="input-fild"
                                        name="roleName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values?.roleName}
                                        error={Boolean(
                                          errors.roleName
                                            ? touched.roleName
                                            : undefined
                                        )}
                                        helperText={
                                          touched.roleName
                                            ? errors.roleName
                                            : undefined
                                        }
                                        variant="standard"
                                        InputProps={{
                                          disableUnderline: true,
                                        }}
                                      ></Index.TextField>
                                    </Index.Box>
                                  </Index.Grid>
                                </Index.Grid>

                                <Index.Box className="main-user-table">
                                  <Index.Paper
                                    sx={{ width: "100%", overflow: "hidden" }}
                                    className="page-table-mains report-table-main"
                                  >
                                    <Index.Box className="my-table-user custom-my-table-user ">
                                      <Index.TableContainer
                                        sx={{ maxHeight: 340 }}
                                        className="table-container"
                                      >
                                        <Index.Table
                                          className="table custom-table"
                                          stickyHeader
                                          sx={{ minWidth: 800 }}
                                          aria-label="sticky table"
                                        >
                                          <Index.TableHead className="table-head">
                                            <Index.TableRow>
                                              <Index.TableCell align="left">
                                                Modules
                                              </Index.TableCell>
                                              <Index.TableCell align="left">
                                                All
                                              </Index.TableCell>
                                              <Index.TableCell align="left">
                                                View
                                              </Index.TableCell>
                                              <Index.TableCell align="left">
                                                Add
                                              </Index.TableCell>
                                              <Index.TableCell align="left">
                                                Edit
                                              </Index.TableCell>
                                              <Index.TableCell align="left">
                                                Delete
                                              </Index.TableCell>
                                            </Index.TableRow>
                                          </Index.TableHead>
                                          <Index.TableBody className="table-body">
                                            {values.permission.map(
                                              (row, i) => (
                                                console.log(row, 248),
                                                (
                                                  <Index.TableRow key={row.tag}>
                                                    <Index.TableCell
                                                      align="left"
                                                      sx={{ width: "25%" }}
                                                    >
                                                      {row.title}
                                                    </Index.TableCell>
                                                    <Index.TableCell align="left">
                                                      <Index.Checkbox
                                                        checked={
                                                          values.permission[i]
                                                            .All
                                                        }
                                                        onChange={(e) =>
                                                          handleAllChange(
                                                            e,
                                                            i,
                                                            setFieldValue
                                                          )
                                                        }
                                                        disabled={
                                                          row.tag ===
                                                            "WalletActivityLogs" ||
                                                          row.tag ===
                                                            "WalletCreatingActivityLogs"
                                                        }
                                                      />
                                                    </Index.TableCell>
                                                    <Index.TableCell align="left">
                                                      <Index.Checkbox
                                                        checked={
                                                          values.permission[i]
                                                            .View
                                                        }
                                                        onChange={(e) =>
                                                          handlePermissionChange(
                                                            e,
                                                            i,
                                                            setFieldValue,
                                                            "View",
                                                            values
                                                          )
                                                        }
                                                      />
                                                    </Index.TableCell>
                                                    <Index.TableCell align="left">
                                                      <Index.Checkbox
                                                        checked={
                                                          values.permission[i]
                                                            .Add
                                                        }
                                                        onChange={(e) =>
                                                          handlePermissionChange(
                                                            e,
                                                            i,
                                                            setFieldValue,
                                                            "Add",
                                                            values
                                                          )
                                                        }
                                                        disabled={
                                                          row.tag ===
                                                            "WalletActivityLogs" ||
                                                          row.tag ===
                                                            "WalletCreatingActivityLogs"
                                                        }
                                                      />
                                                    </Index.TableCell>
                                                    <Index.TableCell align="left">
                                                      <Index.Checkbox
                                                        checked={
                                                          values.permission[i]
                                                            .Edit
                                                        }
                                                        onChange={(e) =>
                                                          handlePermissionChange(
                                                            e,
                                                            i,
                                                            setFieldValue,
                                                            "Edit",
                                                            values
                                                          )
                                                        }
                                                        disabled={
                                                          row.tag ===
                                                            "WalletActivityLogs" ||
                                                          row.tag ===
                                                            "WalletCreatingActivityLogs"
                                                        }
                                                      />
                                                    </Index.TableCell>
                                                    <Index.TableCell align="left">
                                                      <Index.Checkbox
                                                        checked={
                                                          values.permission[i]
                                                            .Delete
                                                        }
                                                        onChange={(e) =>
                                                          handlePermissionChange(
                                                            e,
                                                            i,
                                                            setFieldValue,
                                                            "Delete",
                                                            values
                                                          )
                                                        }
                                                        disabled={
                                                          row.tag ===
                                                            "CampaignManagement" ||
                                                          row.tag ===
                                                            "WalletActivityLogs" ||
                                                          row.tag ===
                                                            "WalletCreatingActivityLogs"
                                                        }
                                                      />
                                                    </Index.TableCell>
                                                  </Index.TableRow>
                                                )
                                              )
                                            )}
                                          </Index.TableBody>
                                        </Index.Table>
                                      </Index.TableContainer>
                                    </Index.Box>
                                  </Index.Paper>
                                </Index.Box>

                                <Index.Grid container spacing={2}>
                                  <Index.Grid item xs={12} sm={2} md={2}>
                                    <Index.Box className="card-btn-flex">
                                      <Index.Box className="primary-btn-main user-submit-btn">
                                        <Index.Button
                                          variant="contained"
                                          type="submit"
                                          disabled={isSubmitting}
                                          className="primary-btn"
                                        >
                                          {editData ? "Update" : "Submit"}
                                        </Index.Button>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>
                                </Index.Grid>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </form>
                      )}
                    </Index.Formik>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </>
          )}
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default AddRole;
//
