import React from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import { ButtonLoader } from "../Button/ButtonLoader";

const DeleteModal = ({ open, setOpen, handleDelete, loading }) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Modal
      className="cus-main-modal"
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="cus-modal">
        <Typography
          className="modal-heading-text"
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          Are You Sure ?
        </Typography>
        <Typography
          className="modal-text"
          id="modal-modal-description"
          sx={{ mt: 2 }}
        >
          Do you really wants to delete this record ?
        </Typography>

        <Box className="main-button-modal">
          <Box className="first-cancel-btn">
            <Button onClick={() => setOpen(false)}>Cancel</Button>
          </Box>
          <Box className="second-delete-btn">
            {loading ? (
              <Button>
                <ButtonLoader />
              </Button>
            ) : (
              <Button
                onClick={() => {
                  handleDelete();
                  handleClose();
                }}
              >
                Delete
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
