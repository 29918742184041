import { useState } from "react";
import Index from "../../../Index";
import { coinValidationSchema } from "../../../../validations/Schema";
import { addEditCoin } from "../../../../redux/slices/AdminService";
import { styled } from "@mui/material";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function AddCoin() {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const { state } = Index.useLocation();
  const [image, setImage] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  let editData = state?.item;

  // InitialValues of form field.
  let initialValues = {};

  if (editData) {
    initialValues = editData;
  } else {
    initialValues = {
      token_id: "",
      symbol: "",
      name: "",
      platform_name: "",
      address: "",
      image: "",
    };
  }

  //On Submit passed data of form value.
  const handleFormSubmit = (values) => {
    setIsDisabled(true);
    const data = new FormData();
    data.append("token_id", values.token_id);
    data.append("symbol", values.symbol);
    data.append("name", values.name);
    data.append("platform_name", values.platform_name);
    data.append("address", values.address);
    if (values?.image) {
      data.append("image", values.image);
    }
    if (editData) {
      data.append("id", editData?._id);
    }
    try {
      dispatch(addEditCoin(data)).then((res) => {
        setIsDisabled(false);
        if (res?.payload?.status === 200 || 201) {
          navigate("/admin/coin");
        }
      });
    } catch (error) {
      setIsDisabled(false);
    }
  };

  const backBtn = () => {
    navigate("/admin/coin");
  };

  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <>
            <Index.Box className="presale-wraper">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex">
                  <Index.Box className="title-main mb-10">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      {editData ? "Edit" : "Add"} Coin
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="common-button blue-button save-btn primary-btn-main">
                    <Index.Button
                      variant="contained"
                      type="submit"
                      className="Particular_model_submit ml-0 primary-btn"
                      onClick={backBtn}
                    >
                      Back
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="form-wrape">
                <Index.Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={coinValidationSchema}
                  onSubmit={handleFormSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Index.Box className="crtl-wrape">
                        <Index.Box className="inner-pad">
                          <Index.Grid container spacing={2}>
                            <Index.Grid item xs={12} sm={6} md={6}>
                              <Index.Box className="inner-pad2">
                                <Index.Typography
                                  className="admin-form-lable"
                                  component="p"
                                >
                                  Token
                                  <span className="astrick-sing">*</span>
                                </Index.Typography>
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  placeholder="Token"
                                  className="input-fild"
                                  name="token_id"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values?.token_id}
                                  error={Boolean(
                                    errors.token_id
                                      ? touched.token_id
                                      : undefined
                                  )}
                                  helperText={
                                    touched.token_id
                                      ? errors.token_id
                                      : undefined
                                  }
                                  variant="standard"
                                  InputProps={{
                                    disableUnderline: true,
                                  }}
                                ></Index.TextField>
                              </Index.Box>
                            </Index.Grid>

                            <Index.Grid item xs={12} sm={6} md={6}>
                              <Index.Box className="inner-pad2">
                                <Index.Typography
                                  className="admin-form-lable"
                                  component="p"
                                >
                                  Symbol
                                  <span className="astrick-sing">*</span>
                                </Index.Typography>
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  placeholder="Symbol"
                                  className="input-fild"
                                  name="symbol"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values?.symbol}
                                  error={Boolean(
                                    errors.symbol ? touched.symbol : undefined
                                  )}
                                  helperText={
                                    touched.symbol ? errors.symbol : undefined
                                  }
                                  variant="standard"
                                  InputProps={{
                                    disableUnderline: true,
                                  }}
                                ></Index.TextField>
                              </Index.Box>
                            </Index.Grid>

                            <Index.Grid item xs={12} sm={6} md={6}>
                              <Index.Box className="inner-pad2">
                                <Index.Typography
                                  className="admin-form-lable"
                                  component="p"
                                >
                                  Name
                                  <span className="astrick-sing">*</span>
                                </Index.Typography>
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  placeholder="Name"
                                  className="input-fild"
                                  name="name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values?.name}
                                  error={Boolean(
                                    errors.name ? touched.name : undefined
                                  )}
                                  helperText={
                                    touched.name ? errors.name : undefined
                                  }
                                  variant="standard"
                                  InputProps={{
                                    disableUnderline: true,
                                  }}
                                ></Index.TextField>
                              </Index.Box>
                            </Index.Grid>

                            <Index.Grid item xs={12} sm={6} md={6}>
                              <Index.Box className="inner-pad2">
                                <Index.Typography
                                  className="admin-form-lable"
                                  component="p"
                                >
                                  Platform Name
                                  <span className="astrick-sing">*</span>
                                </Index.Typography>
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  placeholder="Platform name"
                                  className="input-fild"
                                  name="platform_name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values?.platform_name}
                                  error={Boolean(
                                    errors.platform_name
                                      ? touched.platform_name
                                      : undefined
                                  )}
                                  helperText={
                                    touched.platform_name
                                      ? errors.platform_name
                                      : undefined
                                  }
                                  variant="standard"
                                  InputProps={{
                                    disableUnderline: true,
                                  }}
                                ></Index.TextField>
                              </Index.Box>
                            </Index.Grid>
                            <Index.Grid item xs={12} sm={12} md={12}>
                              <Index.Box className="inner-pad2">
                                <Index.Typography
                                  className="admin-form-lable"
                                  component="p"
                                >
                                  Address
                                  <span className="astrick-sing">*</span>
                                </Index.Typography>
                                <Index.TextareaAutosize
                                  hiddenLabel
                                  minRows={4}
                                  id="filled-hidden-label-normal"
                                  className={
                                    touched?.address && errors?.address
                                      ? "input-fild Mui-error"
                                      : "input-fild"
                                  }
                                  placeholder="Address"
                                  name="address"
                                  value={values.address}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  variant="standard"
                                  InputProps={{
                                    disableUnderline: true,
                                  }}
                                ></Index.TextareaAutosize>

                                {touched?.address && errors?.address && (
                                  <Index.FormHelperText error>
                                    {errors?.address}
                                  </Index.FormHelperText>
                                )}
                              </Index.Box>
                            </Index.Grid>

                            <Index.Grid item xs={12} sm={6} md={6}>
                              {/* <Index.Box className="inner-pad2"> */}
                              <Index.Typography
                                className="admin-form-lable"
                                component="p"
                              >
                                Image
                                <span className="astrick-sing">*</span>
                              </Index.Typography>
                              <Index.Box className="user-image-btn user-image-btn-box">
                                <Index.Box>
                                  <Index.Button
                                    component="label"
                                    variant="contained"
                                    className="user-submit-btn-btn"
                                  >
                                    <Index.CloudUpload />
                                    Upload image
                                    <VisuallyHiddenInput
                                      type="file"
                                      name="image"
                                      accept=".jpeg, .jpg, .png"
                                      onBlur={handleBlur}
                                      onChange={(event) => {
                                        setImage(event.currentTarget.files[0]);
                                        setFieldValue(
                                          "image",
                                          event.currentTarget.files[0]
                                        );
                                      }}
                                    />
                                  </Index.Button>
                                  <Index.Box className="error">
                                    <Index.FormHelperText className="error-message">
                                      {touched?.image && errors?.image}
                                    </Index.FormHelperText>
                                  </Index.Box>
                                </Index.Box>

                                {!errors?.image &&
                                  (image || editData?.image) && (
                                    <Index.Box className="user-img-main">
                                      <img
                                        width={100}
                                        height={100}
                                        src={
                                          image
                                            ? URL.createObjectURL(image)
                                            : editData?.image
                                            ? `${process.env.REACT_APP_IMAGE_URL_LIVE}coinListImages/${editData?.image}`
                                            : ""
                                        }
                                        alt=""
                                        className="user-img"
                                      />
                                    </Index.Box>
                                  )}
                              </Index.Box>
                            </Index.Grid>
                          </Index.Grid>
                          <Index.Box className="card-btn-flex">
                            <Index.Box className="primary-btn-main user-submit-btn">
                              <Index.Button
                                variant="contained"
                                type="submit"
                                disabled={isDisabled}
                                className="primary-btn"
                              >
                                {editData ? "Update" : "Submit"}
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </form>
                  )}
                </Index.Formik>
              </Index.Box>
            </Index.Box>
          </>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default AddCoin;
