import React, { useEffect, useState, useRef } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { PageLoader } from "../../../../component/common/Button/ButtonLoader";
import CommonPagination from "../../../../component/common/PaginationMaster";
const label = { inputProps: { "aria-label": "Switch demo" } };
function UserList() {
  const navigate = Index.useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingRowId, setLoadingRowId] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [removeId, setRemoveId] = useState("");
  const [userList, setUserList] = useState([]);
  const [filterData, setFilterData] = useState([]);

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(10);
  const [pageDefault, setPageDefault] = useState(null);

  const permission = Index.useSelector(
    (state) => state?.admin?.adminData?.data?.role?.permissions
  );

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPaginationPerPage(+event.target.value);
    setCurrentPage(1); // Reset to the first page
  };

  // const handleView = (item) => {
  //   navigate(`/admin/campaign-master/view/${item?._id}`, {
  //     state: { name: item?.name },
  //   });
  // };

  const handleEdit = (item) => {
    console.log(item, "itemedit");
    navigate("/admin/user/add", {
      state: { item: item },
    });
  };
  const handleStatus = async (id) => {
    const data = new URLSearchParams();
    data.append("adminId", id);
    setLoadingRowId(id);
    PageIndex.doPost(PageIndex.Api.USER_ACTIVE_DEACTIVE, data).then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        getUserList();
      }
    });
  };

  const handleDelete = async () => {
    const data = {
      adminId: removeId,
    };
    PageIndex.doPost(PageIndex.Api.DELETE_USER, data).then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        navigate("/admin/user");
        getUserList();
      }
    });
  };

  const handleSearchChange = (e) => {
    const search = e.target.value?.trim();
    const res = userList?.filter((item) => {
      return (
        item?.firstName?.toLowerCase()?.includes(search?.toLowerCase()) ||
        item?.lastName?.toLowerCase()?.includes(search?.toLowerCase()) ||
        item.email?.toLowerCase()?.includes(search?.toLowerCase()) ||
        item.mobileNumber
          ?.toString()
          ?.toLowerCase()
          ?.includes(search?.toLowerCase())
      );
    });
    setFilterData(res);
    setCurrentPage(1);
  };
  //Get list of User
  const getUserList = () => {
    setLoading(true);
    try {
      let userBaseUrl = `${PageIndex.Api.GET_ALL_USER}`;
      PageIndex.doGet(userBaseUrl).then((res) => {
        if (res?.status === 200) {
          setPageDefault(null);
          setUserList(res?.data?.data);
          setFilterData(res?.data?.data);
          setTotalRecordCount(res?.data?.data?.length);
          const totalPages = Math.ceil(
            res?.data?.data?.length / paginationPerPage
          );
          if (currentPage > totalPages) {
            setCurrentPage(totalPages || 1);
          }
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    getUserList();
  }, [currentPage, paginationPerPage]);

  // Apply pagination to filtered data
  const startIndex = (currentPage - 1) * paginationPerPage;
  const endIndex = startIndex + paginationPerPage;
  const paginatedUsers = filterData?.slice(startIndex, endIndex);

  if (permission?.UserManagement?.View === true) {
    return (
      <>
        <Index.DynamicTitle title="presale-wraper" />
        <Index.Box className="presale-wraper">
          <Index.Box className="res-cus-table">
            <Index.Box className="offering-cus-table">
              <Index.Box className="offering-table-head tryed">
                <Index.Box className="title-main">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="page-title"
                  >
                    User Management
                  </Index.Typography>
                </Index.Box>

                {/* <Index.Box className="flex-grp">
                <Index.Box className="search-top-main">
                  <Index.TextField
                    size="small"
                    placeholder="Search"
                    className="search-top-input"
                    onChange={handleSearchChange}
                  />
                  <Index.Box className="search-image">
                    <img src={PageIndex.Png.search}></img>
                  </Index.Box>
                </Index.Box>
               
              </Index.Box> */}

                <Index.Box className="date-search-wrapper">
                  {/* SearchMaster Comman Components */}
                  <Index.Box className="admin-search-main cus-search">
                    <Index.Box className="admin-search-box admin-search-box-user">
                      <Index.Box className="admin-form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="admin-form-control"
                          placeholder="Search..."
                          InputProps={{
                            style: {
                              borderRadius: "30px",
                              fontSize: "12px",
                              border: "1px solid #3e3e3e",
                            },
                          }}
                          onChange={handleSearchChange}
                        />

                        <img
                          src={Index.Svg.search}
                          className="admin-search-grey-img"
                          alt="search grey img"
                        ></img>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  {permission?.UserManagement?.Add === true && (
                    <Index.Box className="common-button blue-button res-blue-button">
                      <Index.RouteLink
                        to="/admin/user/add"
                        className="no-text-decoration primary-btn-main"
                      >
                        {" "}
                        <Index.Button
                          variant="contained"
                          disableRipple
                          className="primary-btn"
                        >
                          Add User
                        </Index.Button>
                      </Index.RouteLink>
                    </Index.Box>
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="border-round-table">
              <Index.TableContainer
                component={Index.Paper}
                className="table-container"
              >
                <Index.Table
                  aria-label="simple table"
                  className="table-design-main supply-table one-line-table invoice-table-set user-management-table trader-list "
                >
                  <Index.TableHead className="gradient-bg">
                    <Index.TableRow>
                      <Index.TableCell align="left">
                        {" "}
                        First Name
                      </Index.TableCell>
                      <Index.TableCell align="left"> Last Name</Index.TableCell>

                      <Index.TableCell align="left">Email</Index.TableCell>
                      <Index.TableCell align="left">Mobile No</Index.TableCell>
                      <Index.TableCell align="left">Role Type</Index.TableCell>
                      <Index.TableCell align="left">
                        {permission?.UserManagement?.Delete === true ||
                        permission?.UserManagement?.Edit === true
                          ? "Action"
                          : ""}
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody>
                    {paginatedUsers?.length > 0 ? (
                      paginatedUsers?.map((item) => (
                        <Index.TableRow key={item?._id}>
                          <Index.Box className="user-table-profile-main">
                            <Index.Box className="user-table-profile">
                              <Index.Avatar
                                sx={{ width: 30, height: 30 }}
                                src={
                                  process.env.REACT_APP_IMAGE_URL_LIVE +
                                  item?.profile
                                }
                              />
                            </Index.Box>
                            <Index.Box className="user-table-name text-name-color">
                              {item?.firstName || "-"}
                            </Index.Box>
                          </Index.Box>
                          <Index.TableCell>{item?.lastName}</Index.TableCell>
                          <Index.TableCell>{item?.email}</Index.TableCell>
                          <Index.TableCell>
                            {item?.mobileNumber}
                          </Index.TableCell>
                          <Index.TableCell>{item?.role?.role}</Index.TableCell>

                          <Index.TableCell>
                            {/* <Index.Button
                            className="admin-table-data-btn"
                            onClick={() => handleView(item)}
                          >
                            <img
                              src={Index.Svg.blueeye}
                              className="admin-icon"
                              alt="View"
                            ></img>
                          </Index.Button> */}
                            <Index.Box className="action-main">
                              {permission?.UserManagement?.Edit === true && (
                                <Index.Switch
                                  className="status-checkbox"
                                  {...label}
                                  checked={item?.isActive}
                                  onClick={() =>
                                    handleStatus(
                                      item?._id,
                                      item?.isActive ? false : true
                                    )
                                  }
                                  color="success"
                                  size="small"
                                />
                              )}
                              {permission?.UserManagement?.Edit === true && (
                                <Index.EditIcon
                                  fontSize="small"
                                  className="delete-icon"
                                  onClick={() => {
                                    handleEdit(item);
                                  }}
                                />
                              )}
                              {permission?.UserManagement?.Delete === true && (
                                <Index.DeleteIcon
                                  fontSize="small"
                                  className="delete-icon"
                                  onClick={() => {
                                    setOpen(true);
                                    setRemoveId(item?._id);
                                  }}
                                />
                              )}
                            </Index.Box>
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          colSpan={10}
                          align="center"
                          className="no-data-cell"
                        >
                          {loading ? <PageLoader /> : "No data found..."}
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                </Index.Table>
              </Index.TableContainer>
            </Index.Box>
            {/* Pagination Common Components */}
            {filterData?.length > 10 && (
              <CommonPagination
                paginationPerPage={paginationPerPage}
                setPaginationPerPage={setPaginationPerPage}
                setPageDefault={setPageDefault}
                totalRecordCount={totalRecordCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </Index.Box>
        </Index.Box>

        <PageIndex.DeleteModal
          open={open}
          setOpen={setOpen}
          handleDelete={handleDelete}
        />
      </>
    );
  } else {
    navigate("/admin/dashboard");
  }
}

export default UserList;
