import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import PageIndex from "../container/PageIndex";
import { getRolePermission } from "../redux/slices/AdminService";
import { useDispatch } from "react-redux";

const PrivateRoute = ({ children }) => {
  const location = PageIndex.useLocation();
  const dispatch = useDispatch();
  const UserLoggedIn = PageIndex.useSelector((state) => state.admin.isLogin);

  const getPermissionData = () => {
    dispatch(getRolePermission());
  };
  useEffect(() => {
    getPermissionData();
  }, [location?.pathname]);

  return UserLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace={true} />
  );
};

export default PrivateRoute;
