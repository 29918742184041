import PageIndex from "../../container/PageIndex";
import { logout } from "../../redux/slices/AdminSlice";
import Index from "../Index";
import React, { useEffect, useState } from "react";

export default function Header(props) {
  // for header profile menu

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = PageIndex.useDispatch();
  const navigate = PageIndex.useNavigate();

  const handlelogout = () => {
    dispatch(logout());
    PageIndex.toast.success("Admin logged out successfully");
    navigate("/");
  };

  // for open Sidebar and add and remove class in body tag

  useEffect(() => {}, [props?.open]);

  return (
    <>
      <Index.Box className={`admin-header-main ${props.open ? "active" : ""}`}>
        <Index.Box className="admin-header-left">
          <Index.Box className="admin-header-logo-main">
            <img
              src={Index.Svg.brand}
              className="admin-sidebar-logo"
              alt="logo"
            />
          </Index.Box>
          <Index.Box className="header-right-wrape">
            <Index.Box className="primary-btn">
              <w3m-button />
            </Index.Box>
            <Index.Box className="admin-header-right">
              <Index.Box className="admin-header-drop-main">
                <Index.Button
                  className="drop-header-btn"
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <Index.Box className="flex-drop-main">
                    <img
                      src={Index.Png.Role}
                      className="admin-header-profile-icon"
                      alt="dashboard bell icon"
                    ></img>
                  </Index.Box>
                </Index.Button>
              </Index.Box>
              <Index.Menu
                className="drop-header-menu"
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {/* <Index.MenuItem
                  onClick={handleClose}
                  className="drop-header-menuitem"
                >
                  <Index.Link
                    to="/dashboard/edit-profile"
                    className="text-decoration-none drop-header-menuitemlink "
                  >
                    My profile
                  </Index.Link>
                </Index.MenuItem> */}
                {/* <Index.MenuItem onClick={handleClose} className='drop-header-menuitem'><Index.Link to='/dashboard' className='text-decoration-none drop-header-menuitemlink'>Account Settings</Index.Link></Index.MenuItem> */}
                {/* <Index.MenuItem onClick={handleClose} className='drop-header-menuitem'><Index.Link to='/dashboard' className='text-decoration-none drop-header-menuitemlink'>Change password</Index.Link></Index.MenuItem> */}
                <Index.MenuItem
                  onClick={handlelogout}
                  className="drop-header-menuitem"
                >
                  <Index.Box className="text-decoration-none drop-header-menuitemlink pink-color">
                    Sign Out
                  </Index.Box>
                </Index.MenuItem>
              </Index.Menu>

              <Index.Box className="bergur-btn-main">
                <Index.Button
                  className="admin-bergur-button"
                  onClick={() => {
                    props.setOpen(!props.open);
                    document.body.classList[!props.open ? "add" : "remove"](
                      "body-overflow"
                    );
                  }}
                >
                  <img
                    src={Index.Svg.bergurmenu}
                    className="bergurmenu-icon"
                    alt="dashboard logo"
                  ></img>
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
