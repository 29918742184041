import React from "react";
import { ClipLoader } from "react-spinners";

export const ButtonLoader = () => {
  return <ClipLoader size={20} color="#ffff" />;
};

export const PageLoader = () => {
  return <ClipLoader size={20} color="gray" />;
};
