import React from 'react';
import Index from '../../container/Index';

const SearchMaster = ({setSearchValue})=>{
    return (
        <Index.Box className="admin-search-main cus-search">
        <Index.Box className="admin-search-box">
          <Index.Box className="admin-form-group">
            <Index.TextField
              fullWidth
              id="fullWidth"
              className="admin-form-control"
              placeholder="Search..."
              InputProps={{
                style: {
                  borderRadius: "30px",
                  fontSize: "12px",
                  border: "1px solid #3e3e3e",
                },
              }}
              onChange={(e) =>
                setSearchValue(e.target.value.toLowerCase())
              }
            />

            <img
              src={Index.Svg.search}
              className="admin-search-grey-img"
              alt="search grey img"
            ></img>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    )
}
export default SearchMaster

