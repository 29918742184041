import React from 'react';
import Index from '../../container/Index';


export default function Paginations({ page, count, onPageChange, rowsPerPage, onRowsPerPageChange }) {
    return (
        <>
            <Index.Box className="pagination-main">
                <Index.TablePagination
                    component="div"
                    page={page}
                    count={count}
                    onPageChange={onPageChange}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[15, 25, 50]}
                    onRowsPerPageChange={onRowsPerPageChange}
                    labelDisplayedRows={({ from, to, count }) =>
                        `Records ${from} to ${to === -1 ? count : to} of ${count}`
                    }
                />
            </Index.Box>
        </>
    )
}
