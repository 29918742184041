import React from "react";
import { BrowserRouter, Route, Routes as Routess } from "react-router-dom";
import Dashboard from "../container/admin/pages/dashboard/Dashboard";
import Home from "../container/admin/pages/home/Home";
import Login from "../container/admin/auth/Login";
import ComingSoon from "../container/admin/pages/comingSoon/ComingSoon";
import PrivateRoute from "./PrivateRoute";
import PresaleActivity from "../container/admin/pages/home/PresaleActivity";
import AddCampaign from "../container/admin/pages/campaign/Add";
import CompainList from "../container/admin/pages/campaign/List";
import ViewReferral from "../container/admin/pages/campaign/View";
import WalletActivityLogs from "../container/admin/pages/walletActivityLogs/List";
import PresalePhases from "../container/admin/pages/home/PresalePhases";
import History from "../container/admin/pages/History/History";
import WalletRegisterImportActivity from "../container/admin/pages/WalletRegisterImportActivity/List";
import UserList from "../container/admin/pages/userMaster/List";
import AddUser from "../container/admin/pages/userMaster/Add";
import RoleList from "../container/admin/pages/roleMaster/List";
import AddRole from "../container/admin/pages/roleMaster/Add";
import ForgotPassword from "../container/admin/auth/ForgotPassword";
import Otp from "../container/admin/auth/Otp";
import ResetPassword from "../container/admin/auth/ResetPassword";
import AddCoin from "../container/admin/pages/coinMaster/Add";
import CoinList from "../container/admin/pages/coinMaster/List";
// import History from "../container/admin/pages/History/History";

const Routes = () => {
  return (
    <BrowserRouter>
      <Routess>
        <Route path="/admin/login" element={<Login />} />
        <Route path="/admin/forgot-password" element={<ForgotPassword />} />
        <Route path="/admin/otp" element={<Otp />} />
        <Route path="/admin/reset-password" element={<ResetPassword />} />

        <Route path="/" element={<ComingSoon />} />

        <Route element={<PrivateRoute />}>
          <Route path="/admin" element={<Dashboard />}>
            <Route path="dashboard" element={<Home />} />
            <Route path="presaleactivity" element={<PresaleActivity />} />
            <Route path="campaign-master" element={<CompainList />} />
            <Route path="campaign-master/add" element={<AddCampaign />} />
            <Route path="campaign-master/edit/:id" element={<AddCampaign />} />
            <Route path="campaign-master/view/:id" element={<ViewReferral />} />
            <Route path="wallet-activity" element={<WalletActivityLogs />} />
            <Route path="presalePhase-activity" element={<PresalePhases />} />
            <Route path="history" element={<History />} />
            <Route
              path="wallet-creating-activity"
              element={<WalletRegisterImportActivity />}
            />
            <Route path="user" element={<UserList />} />
            <Route path="user/add" element={<AddUser />} />
            <Route path="role" element={<RoleList />} />
            <Route path="role/add" element={<AddRole />} />
            <Route path="coin/add" element={<AddCoin />} />
            <Route path="coin" element={<CoinList />} />
          </Route>
        </Route>
      </Routess>
    </BrowserRouter>
  );
};

export default Routes;
