import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import CommonPagination from "../../../../component/common/PaginationMaster";
import { PageLoader } from "../../../../component/common/Button/ButtonLoader";
import SearchMaster from "../../../../component/common/SearchMaster";

function CoinList() {
  const navigate = Index.useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(10);
  const [pageDefault, setPageDefault] = useState(null);
  const [open, setOpen] = useState(false);
  const [coinList, setCoinList] = useState([]);
  const [removeId, setRemoveId] = useState("");
  const permission = Index.useSelector(
    (state) => state?.admin?.adminData?.data?.role?.permissions
  );

  //Get list of coin list
  const getCoinList = () => {
    setLoading(true);
    try {
      let coinBaseUrl = `${PageIndex.Api.GET_ALL_COIN}`;

      const newParams = new URLSearchParams();
      newParams.append(
        "page",
        pageDefault !== null ? pageDefault : currentPage
      );
      newParams.append("limit", paginationPerPage);
      if (searchValue) {
        newParams.append("search", searchValue);
      }

      coinBaseUrl += `?${newParams.toString()}`;

      PageIndex.doGet(coinBaseUrl).then((res) => {
        if (res?.status === 200) {
          setCurrentPage(res?.data?.currentPage);
          setTotalRecordCount(res?.data?.totalCoins);
          setPageDefault(null);
          setCoinList(res?.data?.data);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      });
    } catch (error) {}
  };

  //Page load coin list data.
  useEffect(() => {
    getCoinList();
  }, [paginationPerPage, currentPage, searchValue]);

  const handleEdit = (item) => {
    navigate("/admin/coin/add", {
      state: { item: item },
    });
  };

  const handleDelete = async () => {
    const data = {
      id: removeId,
    };
    PageIndex.doPost(PageIndex.Api.DELETE_COIN, data).then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        navigate("/admin/coin");
        getCoinList();
      }
    });
  };
  if (permission?.CoinManagement?.View === true) {
    return (
      <>
        <Index.DynamicTitle title="presale-wraper" />
        <Index.Box className="presale-wraper">
          <Index.Box className="res-cus-table">
            <Index.Box className="offering-cus-table">
              <Index.Box className="offering-table-head tryed">
                <Index.Box className="title-main">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="page-title"
                  >
                    Coin Management
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="date-search-wrapper">
                  {/* SearchMaster Comman Components */}
                  <SearchMaster setSearchValue={setSearchValue} />

                  {permission?.CoinManagement?.Add === true && (
                    <Index.Box className="common-button blue-button res-blue-button">
                      <Index.RouteLink
                        to="/admin/coin/add"
                        className="no-text-decoration primary-btn-main"
                      >
                        {" "}
                        <Index.Button
                          variant="contained"
                          disableRipple
                          className="primary-btn"
                        >
                          Add Coin
                        </Index.Button>
                      </Index.RouteLink>
                    </Index.Box>
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="border-round-table">
              <Index.TableContainer
                component={Index.Paper}
                className="table-container"
              >
                <Index.Table
                  aria-label="simple table"
                  className="table-design-main supply-table one-line-table invoice-table-set user-management-table trader-list "
                >
                  <Index.TableHead className="gradient-bg">
                    <Index.TableRow>
                      <Index.TableCell align="left">Token</Index.TableCell>
                      <Index.TableCell align="left">Symbol</Index.TableCell>
                      <Index.TableCell align="left">Name</Index.TableCell>
                      <Index.TableCell align="left">
                        Platform Name
                      </Index.TableCell>
                      <Index.TableCell>Image</Index.TableCell>
                      <Index.TableCell>Address</Index.TableCell>

                      <Index.TableCell>
                        {permission?.CoinManagement?.Delete === true ||
                        permission?.CoinManagement?.Edit === true
                          ? "Action"
                          : ""}
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody>
                    {coinList?.length ? (
                      coinList.map((item, index) => (
                        <Index.TableRow key={item?._id}>
                          <Index.TableCell>
                            {item?.token_id || "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {item?.symbol || "-"}
                          </Index.TableCell>
                          <Index.TableCell>{item?.name || "-"}</Index.TableCell>
                          <Index.TableCell>
                            {item?.platform_name || "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {item?.image ? (
                              <Index.Box className="coin-img-main">
                                <Index.IconButton
                                  className="coin-img-main-icon-btn"
                                  onClick={() => {
                                    if (item?.image) {
                                      window.open(
                                        `${process.env.REACT_APP_IMAGE_URL_LIVE}coinListImages/${item?.image}`
                                      );
                                    }
                                  }}
                                >
                                  <img
                                    className="coin-img"
                                    src={
                                      item?.image
                                        ? `${process.env.REACT_APP_IMAGE_URL_LIVE}coinListImages/${item?.image}`
                                        : "-"
                                    }
                                    alt="No img"
                                  />
                                </Index.IconButton>
                              </Index.Box>
                            ) : (
                              <span style={{ marginLeft: "24px" }}>-</span>
                            )}
                          </Index.TableCell>
                          <Index.TableCell>{item?.address}</Index.TableCell>
                          <Index.TableCell>
                            <Index.Box className="action-main">
                              {permission?.CoinManagement?.Edit === true && (
                                <Index.EditIcon
                                  fontSize="small"
                                  className="delete-icon"
                                  onClick={() => {
                                    handleEdit(item);
                                  }}
                                />
                              )}
                              {permission?.CoinManagement?.Delete === true && (
                                <Index.DeleteIcon
                                  fontSize="small"
                                  className="delete-icon"
                                  onClick={() => {
                                    setOpen(true);
                                    setRemoveId(item?._id);
                                  }}
                                />
                              )}
                            </Index.Box>
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          colSpan={10}
                          align="center"
                          className="no-data-cell"
                        >
                          {loading ? <PageLoader /> : "No data found..."}
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                  {/* )} */}
                </Index.Table>
              </Index.TableContainer>
            </Index.Box>
            {/* Pagination Comman Components */}
            <CommonPagination
              paginationPerPage={paginationPerPage}
              setPaginationPerPage={setPaginationPerPage}
              setPageDefault={setPageDefault}
              totalRecordCount={totalRecordCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Index.Box>
        </Index.Box>
        <PageIndex.DeleteModal
          open={open}
          setOpen={setOpen}
          handleDelete={handleDelete}
        />
      </>
    );
  } else {
    navigate("/admin/dashboard");
  }
}

export default CoinList;
