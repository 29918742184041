import React, { useState } from "react";
import Index from "../Index";
import { useLocation } from "react-router";

export default function Sidebar(props) {
  // open sidebar usign handalclick

  const [open, setOpen] = React.useState(false);
  const location = useLocation();

  const path = location?.pathname;
  const handleClickAccount = () => {
    setOpen(!open);
  };

  const roleType = Index.useSelector(
    (state) => state?.admin?.adminData?.data?.role
  );
  const permission = Index.useSelector(
    (state) => state?.admin?.adminData?.data?.role?.permissions
  );
  console.log(permission, 17);
  console.log(roleType?.role, 18);

  return (
    <>
      <Index.Box
        className={`admin-sidebar-main scrollbar ${props.open ? "active" : ""}`}
      >
        <Index.Box className="admin-sidebar-inner-main">
          <Index.Box className="admin-sidebar-logo-main">
            <Index.Button
              onClick={() => {
                props.setOpen(!props.open);
                document.body.classList[!props.open ? "add" : "remove"](
                  "body-overflow"
                );
              }}
            >
              <img src={Index.Svg.close} className="close-icon" alt="logo" />
            </Index.Button>
          </Index.Box>
          <Index.Box className="admin-sidebar-list-main">
            <Index.List className="admin-sidebar-list">
              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/dashboard"
                  className={`${
                    path == "/admin/dashboard"
                      ? "admin-sidebar-link active"
                      : "admin-sidebar-link"
                  }`}
                >
                  <img
                    src={Index.Svg.dashboard}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  Dashboard
                </Index.Link>
              </Index.ListItem>

              {/* <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/presaleactivity"
                  className={`${
                    path == "/admin/presaleactivity"
                      ? "admin-sidebar-link active"
                      : "admin-sidebar-link"
                  }`}
                >
                  <img
                    src={Index.Svg.dashboard}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  Presale Setup
                </Index.Link>
              </Index.ListItem> */}

              {/* <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/presalePhase-activity"
                  className={`${
                    path == "/admin/presalePhase-activity"
                      ? "admin-sidebar-link active"
                      : "admin-sidebar-link"
                  }`}
                >
                  <img
                    src={Index.Svg.dashboard}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  Presale Token Transfer
                </Index.Link>
              </Index.ListItem> */}

              {/* <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/history"
                  className={`${
                    path == "/admin/history"
                      ? "admin-sidebar-link active"
                      : "admin-sidebar-link"
                  }`}
                >
                  <img
                    src={Index.Svg.dashboard}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  Presale History
                </Index.Link>
              </Index.ListItem> */}
              {permission?.UserManagement?.View === true && (
                <Index.ListItem className="admin-sidebar-listitem">
                  <Index.Link
                    to="/admin/user"
                    className={`${
                      path == "/admin/user"
                        ? "admin-sidebar-link active"
                        : "admin-sidebar-link"
                    }`}
                  >
                    {/* <img
                      src={Index.Svg.dashboard}
                      alt="sidebar icon"
                      className="admin-sidebar-icons"
                    /> */}
                    <Index.ManageAccountsOutlined className="admin-sidebar-icons" />
                    User Management
                  </Index.Link>
                </Index.ListItem>
              )}

              {roleType?.role == "admin" && (
                <Index.ListItem className="admin-sidebar-listitem">
                  <Index.Link
                    to="/admin/role"
                    className={`${
                      path == "/admin/role"
                        ? "admin-sidebar-link active"
                        : "admin-sidebar-link"
                    }`}
                  >
                    {/* <img
                      src={Index.Svg.dashboard}
                      alt="sidebar icon"
                      className="admin-sidebar-icons"
                    /> */}
                    <Index.ScatterPlotOutlined className="admin-sidebar-icons" />
                    Role Management
                  </Index.Link>
                </Index.ListItem>
              )}

              {permission?.CoinManagement?.View === true && (
                <Index.ListItem className="admin-sidebar-listitem">
                  <Index.Link
                    to="/admin/coin"
                    className={`admin-sidebar-link ${
                      path.includes("/admin/coin") ? "active" : ""
                    }`}
                  >
                    <img
                      src={Index.Svg.dashboard}
                      alt="sidebar icon"
                      className="admin-sidebar-icons"
                    />
                    Coin Management
                  </Index.Link>
                </Index.ListItem>
              )}

              {permission?.CampaignManagement?.View === true && (
                <Index.ListItem className="admin-sidebar-listitem">
                  <Index.Link
                    to="/admin/campaign-master"
                    className={`admin-sidebar-link ${
                      path.includes("/admin/campaign-master") ? "active" : ""
                    }`}
                  >
                    <img
                      src={Index.Svg.dashboard}
                      alt="sidebar icon"
                      className="admin-sidebar-icons"
                    />
                    Campaign Management
                  </Index.Link>
                </Index.ListItem>
              )}

              {permission?.WalletActivityLogs?.View === true && (
                <Index.ListItem className="admin-sidebar-listitem">
                  <Index.Link
                    to="/admin/wallet-activity"
                    className={`admin-sidebar-link ${
                      path.includes("/admin/wallet-activity") ? "active" : ""
                    }`}
                  >
                    <img
                      src={Index.Svg.dashboard}
                      alt="sidebar icon"
                      className="admin-sidebar-icons"
                    />
                    Wallet Activity Logs
                  </Index.Link>
                </Index.ListItem>
              )}
              {permission?.WalletCreatingActivityLogs?.View === true && (
                <Index.ListItem className="admin-sidebar-listitem">
                  <Index.Link
                    to="/admin/wallet-creating-activity"
                    className={`admin-sidebar-link ${
                      path.includes("/admin/wallet-creating-activity")
                        ? "active"
                        : ""
                    }`}
                  >
                    <img
                      src={Index.Svg.dashboard}
                      alt="sidebar icon"
                      className="admin-sidebar-icons"
                    />
                    Wallet Creating Logs
                  </Index.Link>
                </Index.ListItem>
              )}

              {/* <Index.ListItem className="admin-sidebar-listitem admin-submenu-listitem-main">
                  <Index.Box className="admin-sidebar-link">
                    <Index.Box
                      className="admin-sidebar-dropdown"
                      onClick={handleClickAccount}
                    >
                      <img
                        src={Index.Svg.cms}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Cms
                  
                      {open ? (
                        <Index.ExpandMore className="expandmore-icon" />
                      ) : (
                        <Index.ExpandLess className="expandless-icon" />
                      )}
                    </Index.Box>
                    <Index.Box className="submenu-main">
                      <Index.Collapse
                        in={open}
                        timeout="auto"
                        unmountOnExit
                        className="submenu-collapse"
                      >
                        <Index.List
                          component="div"
                          disablePadding
                          className="admin-sidebar-submenulist"
                        >
                          <>
                            <Index.ListItem className="admin-sidebar-listitem">
                              <Index.Link
                                to="/dashboard/terms-condition"
                                className={`${
                                  path == "/dashboard/terms-condition"
                                    ? "admin-sidebar-link active"
                                    : "admin-sidebar-link"
                                }`}
                              >
                                Terms and conditions
                              </Index.Link>
                            </Index.ListItem>
                          </>

                          <>
                            <Index.ListItem className="admin-sidebar-listitem">
                              <Index.Link
                                to="/dashboard/privacy-policy"
                                className={`${
                                  path == "/dashboard/privacy-policy"
                                    ? "admin-sidebar-link active"
                                    : "admin-sidebar-link"
                                }`}
                              >
                                Privacy policy
                              </Index.Link>
                            </Index.ListItem>
                          </>

                          <>
                            <Index.ListItem className="admin-sidebar-listitem">
                              <Index.Link
                                to="/dashboard/add-social-links"
                                className={`${
                                  path == "/dashboard/add-social-links"
                                    ? "admin-sidebar-link active"
                                    : "admin-sidebar-link"
                                }`}
                              >
                                Social links
                              </Index.Link>
                            </Index.ListItem>
                          </>
                        </Index.List>
                      </Index.Collapse>
                    </Index.Box>
                  </Index.Box>
                </Index.ListItem> */}
            </Index.List>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
