import React, { useEffect, useState, useRef } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { PageLoader } from "../../../../component/common/Button/ButtonLoader";
import CommonPagination from "../../../../component/common/PaginationMaster";

function RoleList() {
  const navigate = Index.useNavigate();
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [removeId, setRemoveId] = useState("");
  const [open, setOpen] = useState(false);
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(10);
  const [pageDefault, setPageDefault] = useState(null);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPaginationPerPage(+event.target.value);
    setCurrentPage(1); // Reset to the first page
  };

  //Get list of role list
  const getRoleList = () => {
    setLoading(true);
    try {
      let roleBaseUrl = `${PageIndex.Api.GET_ALL_ROLE_LIST}`;
      PageIndex.doGet(roleBaseUrl).then((res) => {
        if (res?.status === 200) {
          setRole(res?.data?.data);
          setFilterData(res?.data?.data);
          setTotalRecordCount(res?.data?.data?.length);
          const totalPages = Math.ceil(
            res?.data?.data?.length / paginationPerPage
          );
          if (currentPage > totalPages) {
            setCurrentPage(totalPages || 1);
          }
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      });
    } catch (error) {}
  };

  //Page load role data.
  useEffect(() => {
    getRoleList();
  }, [currentPage, paginationPerPage]);

  // Apply pagination to filtered data
  const startIndex = (currentPage - 1) * paginationPerPage;
  const endIndex = startIndex + paginationPerPage;
  const paginatedUsers = filterData?.slice(startIndex, endIndex);

  const handleEditClick = (data) => {
    navigate("/admin/role/add", { state: { data: data || "" } });
  };
  const handleDelete = async () => {
    const data = {
      id: removeId,
    };
    PageIndex.doPost(PageIndex.Api.DELETE_ROLE, data).then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        getRoleList();
      }
    });
  };

  return (
    <>
      <Index.DynamicTitle title="presale-wraper" />
      <Index.Box className="presale-wraper">
        <Index.Box className="res-cus-table">
          <Index.Box className="offering-cus-table">
            <Index.Box className="offering-table-head tryed">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Role Management
                </Index.Typography>
              </Index.Box>

              <Index.Box className="common-button blue-button res-blue-button">
                <Index.RouteLink
                  to="/admin/role/add"
                  className="no-text-decoration primary-btn-main"
                >
                  {" "}
                  <Index.Button
                    variant="contained"
                    disableRipple
                    className="primary-btn"
                  >
                    Add Role
                  </Index.Button>
                </Index.RouteLink>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="border-round-table">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main supply-table one-line-table invoice-table-set user-management-table trader-list "
              >
                <Index.TableHead className="gradient-bg">
                  <Index.TableRow>
                    <Index.TableCell align="left">Sr.No</Index.TableCell>
                    <Index.TableCell align="left">Role</Index.TableCell>
                    <Index.TableCell align="left">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>

                <Index.TableBody>
                  {paginatedUsers?.length > 0 ? (
                    paginatedUsers?.map((item, index) => (
                      <Index.TableRow key={item?._id}>
                        <Index.TableCell>{index + 1}</Index.TableCell>
                        <Index.TableCell>{item?.role}</Index.TableCell>
                        <Index.TableCell>
                          <Index.Box className="action-main">
                            <Index.EditIcon
                              fontSize="small"
                              className="delete-icon"
                              onClick={() => handleEditClick(item)}
                            />{" "}
                            <Index.DeleteIcon
                              fontSize="small"
                              className="delete-icon"
                              onClick={() => {
                                setOpen(true);
                                setRemoveId(item?._id);
                              }}
                            />
                          </Index.Box>
                        </Index.TableCell>
                      </Index.TableRow>
                    ))
                  ) : (
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={10}
                        align="center"
                        className="no-data-cell"
                      >
                        {loading ? <PageLoader /> : "No data found..."}
                      </Index.TableCell>
                    </Index.TableRow>
                  )}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          {/* Pagination Common Components */}
          {filterData?.length > 10 && (
            <CommonPagination
              paginationPerPage={paginationPerPage}
              setPaginationPerPage={setPaginationPerPage}
              setPageDefault={setPageDefault}
              totalRecordCount={totalRecordCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </Index.Box>
      </Index.Box>

      <PageIndex.DeleteModal
        open={open}
        setOpen={setOpen}
        handleDelete={handleDelete}
      />
    </>
  );
}

export default RoleList;
