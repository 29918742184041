import {
  getDashboardData,
  getStatisticData,
  getDateFilterGraphData
} from "../../../../redux/slices/AdminService";
import Index from "../../../Index";
import { useEffect, useState } from "react";
import PageIndex from "../../../PageIndex";
import CountUp from "react-countup";
import MyResponsivePie from "./PieChart";
import BarChart from "./BarChart";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DateBarChart from "./DateBarChart";


// for progress bar design

// const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
//   height: 10,
//   borderRadius: 5,
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     backgroundColor:
//       theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
//   },
//   [`& .${linearProgressClasses.bar}`]: {
//     borderRadius: 5,
//     backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
//   },
// }));

// for table design

export default function Home() {
  const [dashboadData, setDashboardData] = useState([]);
  const dispatch = PageIndex.useDispatch();
  const durations = [
    {
      id: "7",
      label: "This Week",
    },
    {
      id: "30",
      label: "This Month",
    },
    {
      id: "365",
      label: "This Year",
    },
  ];
  const [currentDuration, setCurrentDuration] = useState(durations[0].label);
  const [BarData, setBarData] = useState([]);
  const [BarDateData, setBarDateData] = useState([]);
  const [selectedDate, setSelectedDate] = useState([null, null]);
  const [DateChange, setChanageDate] = useState([]);
  const [AndroidData, setAndroidData] = useState([
    {
      id: "Active",
      label: "Active",
      value: 100,
    },
    {
      id: "Inactive",
      label: "Inactive",
      value: 0,
    },
  ]);

  const [IOSData, setIOSData] = useState([
    {
      id: "Active",
      label: "Active",
      value: 100,
    },
    {
      id: "Inactive",
      label: "Inactive",
      value: 0,
    },
  ]);

  const handleChangeDuration = (data) => {
    if (data !== currentDuration) {
      if (data === "This Week") {
        fetchStats("weekly");
      } else if (data === "This Month") {
        fetchStats("monthly");
      } else if (data === "This Year") {
        fetchStats("yearly");
      }
      setCurrentDuration(data);
    }
  };

  const fetchStats = async (duration) => {
    if (duration === "weekly") {
      const feed = {
        limit: "weekly",
      };
      const res = await getStatisticData(feed);
      const data = res?.data?.map((key) => ({
        entity: key?.Day,
        "Total Wallets": key?.count,
        "Active Wallets": key?.isActiveCount,
      }));
      setBarData(data);
    } else if (duration === "yearly") {
      const feed = {
        limit: "yearly",
      };
      const res = await getStatisticData(feed);
      const data = res?.data?.map((key) => ({
        entity: (key?.month).slice(0, 3),
        "Total Wallets": key?.count,
        "Active Wallets": key?.isActiveCount,
      }));
      const monthsData = {
        Jan: 1,
        Feb: 2,
        Mar: 3,
        Apr: 4,
        May: 5,
        Jun: 6,
        Jul: 7,
        Aug: 8,
        Sep: 9,
        Oct: 10,
        Nov: 11,
        Dec: 12,
      };
      const sortedData = data.sort(
        (a, b) => monthsData[a.entity] - monthsData[b.entity]
      );
      setBarData(sortedData);
    } else if (duration === "monthly") {
      const feed = {
        limit: "monthly",
      };
      const res = await getStatisticData(feed);
      const data = res?.data?.map((key) => ({
        entity: key?.day,
        "Total Wallets": key?.count,
        "Active Wallets": key?.isActiveCount,
      }));
      setBarData(data);
    } else {
      console.log("no data");
    }
  };

  useEffect(() => {
    fetchStats("weekly");
    dispatch(getDashboardData()).then((res) => {
      setDashboardData(res?.payload?.data);
      setAndroidData([
        {
          id: "Active",
          label: "Active",
          value: res?.payload?.data?.activeAndroidWallet,
        },
        {
          id: "Inactive",
          label: "Inactive",
          value:
            res?.payload?.data?.totalAndroidWallet -
            res?.payload?.data?.activeAndroidWallet,
        },
      ]);
      setIOSData([
        {
          id: "Active",
          label: "Active",
          value: res?.payload?.data?.activeIosWallet,
        },
        {
          id: "Inactive",
          label: "Inactive",
          value:
            res?.payload?.data?.totalIosWallet -
            res?.payload?.data?.activeIosWallet,
        },
      ]);
    });
  }, []);

  const handlerDateFilter = async (e = null) => {
    let filterDate;
    if (!e) {  
      const currentDate = Index.dayjs();
      // Get the start of the current week (Sunday)
      const fromDate = currentDate.startOf('week').toDate();
      // Get the end of the current week (Saturday)
      // const toDate = currentDate.endOf('week').toDate();
      const toDate = currentDate.toDate();
      setSelectedDate([Index.dayjs(fromDate), Index.dayjs(toDate)]);
      filterDate = { fromDate, toDate }
    }
    else {
      let startDate = e[0]?.$d;
      let endDate = e[1]?.$d;
      setChanageDate(e);
      filterDate = { fromDate: startDate, toDate: endDate }
    }
    const res = await getDateFilterGraphData(filterDate);
    const data = res?.data?.map((key) => ({
      entity: key?.day,
      "Total Wallets": key?.count,
      "Active Wallets": key?.isActiveCount,
    }));
    setBarDateData(data);
  }
  useEffect(() => {
    handlerDateFilter()
  }, [])

  // const calculateMaxDateforDisable = (fromDate) => {
  //   DateChange[1]
  //   if(fromDate){

  //     return Index.dayjs((fromDate).add(30, 'day').toDate());
  //   }
  // };

  // console.log( "515", DateChange[0], DateChange && Index.dayjs(DateChange[0] ? DateChange[0] : null).isSame(Index.dayjs(), 'day') , Index.dayjs(DateChange[0] ? DateChange[0] : null).toDate() )


//   const calculateMaxDateforDisable = (fromDate) => {
//     if (!fromDate) return null; // Return null if fromDate is not provided
//     const maxDate = Index.dayjs((fromDate).add(30, 'day').toDate());
//     console.log("232", fromDate && Index.dayjs(fromDate ?fromDate : null).isAfter(Index.dayjs(), 'day') ? "if": "else");
//     return fromDate && Index.dayjs(fromDate ?fromDate : null).isAfter(Index.dayjs(), 'day') ? Index.dayjs((fromDate)) : maxDate;
// };

const calculateMaxDateforDisable = (fromDate) => {
  if (!fromDate) return null; // Return null if fromDate is not provided
  
  const maxDate = Index.dayjs((fromDate).add(30, 'day').toDate()); // Calculate max date by adding 30 days to fromDate
  
  if (Index.dayjs(maxDate).isAfter(Index.dayjs(), 'day')) {
    const _currentDate = Index.dayjs();
    // const _toDate = _currentDate.toDate();
      // If maxDate is after current date, disable dates starting from the current date
      return Index.dayjs((_currentDate).toDate());
  } else {
      // Otherwise, disable dates starting from fromDate plus 30 days
      return maxDate;
  }
};

  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Typography
          className="admin-page-title"
          component="h2"
          variant="h2"
        >
          Dashboard
        </Index.Typography>
        <Index.Typography className="admin-page-para" component="p" variant="p">
          {/* Whole data about business here */}
        </Index.Typography>
        <Index.Box className="admin-dashboad-row">
          {/* First Row */}
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row-dashboard"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
            >
              {/* Active Wallets */}
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 3",
                }}
                className="grid-column active"
              >
                <Index.Box className="admin-dashboard-box">
                  <Index.Box className="admin-dashboard-inner-box">
                    <Index.Box className="admin-dash-left">
                      <Index.Typography
                        className="admin-dash-text"
                        variant="p"
                        component="p"
                      >
                        Total Active Wallets{" "}
                      </Index.Typography>
                      <Index.Typography
                        className="admin-dash-price"
                        variant="h1"
                        component="h1"
                      >
                        <CountUp
                          delay={0.4}
                          end={dashboadData?.activeWallets}
                          duration={0.7}
                        />
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="admin-dash-right">
                      <img
                        src={Index.Svg.walletBlack}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              
              {/* Total Wallets */}
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 3",
                }}
                className="grid-column total"
              >
                <Index.Box className="admin-dashboard-box">
                  <Index.Box className="admin-dashboard-inner-box">
                    <Index.Box className="admin-dash-left">
                      <Index.Typography
                        className="admin-dash-text"
                        variant="p"
                        component="p"
                      >
                        Total Wallets
                      </Index.Typography>
                      <Index.Typography
                        className="admin-dash-price"
                        variant="h1"
                        component="h1"
                      >
                        <CountUp
                          delay={0.4}
                          end={dashboadData?.totalWallets}
                          duration={0.7}
                        />
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="admin-dash-right">
                      <img
                        src={Index.Svg.wallet}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 3, sm: 3, md: 3, lg: 3 }}
            >
              {/* IOS Chart */}
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column box-frame"
              >
                <Index.Typography component="h4">
                  IOS
                </Index.Typography>
                <Index.Box className="chart-pad">
                  <MyResponsivePie data={IOSData} />
                </Index.Box>
              </Index.Box>

              {/* Android Chart */}              
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column box-frame"
              >
                {" "}
                <Index.Typography component="h4">
                  Android
                </Index.Typography>
                <Index.Box className="chart-pad">
                  <MyResponsivePie data={AndroidData} />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* Second Row */}
          <Index.Box sx={{ width: 1, marginTop: "20px" }} className="grid-main box-frame">
            <Index.Box
              display="grid"
              className="display-row-dashboard header-rows"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 4",
                }}
                className="grid-column header-text"
              > Wallet Activity </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 4",
                }}
                className="grid-column"
              ></Index.Box>
              {/* Button */}
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 4",
                }}
                className="grid-column card-head"
              >
                <Index.Box className="input-box">
                <FormControl fullWidth color="info">
                  <Select
                    displayEmpty
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={currentDuration}
                    sx={{
                      height: "2.5rem",
                      color: "white",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "white",
                      },
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                  // variant="filled"
                  >
                    {durations?.map((duration) => {
                      return (
                        <MenuItem
                          value={duration?.label}
                          onClick={() => {
                            handleChangeDuration(duration?.label);
                          }}
                        >
                          {duration?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box
              display="grid"
              className="display-row-dashboard"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
            >
              {/* Total Wallets */}
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <BarChart data={BarData} />
              </Index.Box>
            </Index.Box>
          </Index.Box>


         

          {/* five Row */}
          <Index.Box sx={{ width: 1, marginTop: "20px" }} className="grid-main box-frame">
            <Index.Box
              display="grid"
              className="display-row-dashboard  header-rows"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 4",
                }}
                className="grid-column header-text"
              > Statistics </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 4",
                }}
                className="grid-column header-text"
              > </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 4",
                }}
                className="grid-column card-head datepicker-wrape"
              >
                <Index.Box className="input-box">
                    <Index.Box className="form-group">
                      <Index.LocalizationProvider dateAdapter={Index.AdapterDayjs}>
                        <Index.DemoContainer components={["SingleInputDateRangeField"]}>
                          <Index.DateRangePicker className="date-picker-custom form-control"
                            format="DD-MM-YYYY"
                            value={selectedDate}
                            maxDate={DateChange[0] ? calculateMaxDateforDisable(DateChange[0]) : Index.dayjs((DateChange[0]))} 
                            slots={{ field: Index.SingleInputDateRangeField }}
                            onChange={(e) => handlerDateFilter(e)}
                          />
                        </Index.DemoContainer>
                      </Index.LocalizationProvider>
                    </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              display="grid"
              className="display-row-dashboard  header-rows"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
            >
              {/* Total Wallets */}
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <DateBarChart data={BarDateData} />
              </Index.Box>
            </Index.Box>
          </Index.Box>

        </Index.Box>
      </Index.Box>

      {/* Wide Manhattan Chart */}
    </>
  );
}
