import React, { useEffect, useState, useRef } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
// import ExcelExportHelper from "../../../../component/common/ExcelExportHelper";
import QrCodeGenerate from "../../../../component/common/QrCodeGenerate";
import CommonPagination from "../../../../component/common/PaginationMaster";
import { PageLoader } from "../../../../component/common/Button/ButtonLoader";

function CampaignList() {
  const navigate = Index.useNavigate();
  const [loading, setLoading] = useState(false);
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(10);
  const [pageDefault, setPageDefault] = useState(null);

  //open qr code model.
  const [open, setOpen] = useState(false);
  // const [campaignList, setCompainLlist] = useState([]);
  const [campaignList, setCompainLlist] = useState([]);
  //Referral Link
  const [referralLink, setReferralLink] = useState("");
  const downloadRef = useRef(null);
  const QRCODE_BASEURL = process.env.REACT_APP_QRCODE_URL;

  const permission = Index.useSelector(
    (state) => state?.admin?.adminData?.data?.role?.permissions
  );

  // Delete record state
  // const [deleteOpen, setDeleteOpen] = useState(false);
  // const [rowId, setRowId] = useState("");

  //Delete Model
  // const handleDeleteOpen = () => {
  //     setDeleteOpen(true);
  // };
  // const handleDeleteClose = () => {
  //     setDeleteOpen(false);
  //     setRowId("");
  // };

  //Handle campaign view
  const handleView = (item) => {
    navigate(`/admin/campaign-master/view/${item?._id}`, {
      state: { name: item?.name },
    });
  };

  //Get list of compaign list
  const getCompainList = () => {
    setLoading(true);
    try {
      let compaignBaseUrl = `${PageIndex.Api.GET_COMPAIN_LIST}`;

      const newParams = new URLSearchParams();
      newParams.append(
        "page",
        pageDefault !== null ? pageDefault : currentPage
      );
      newParams.append("size", paginationPerPage);

      compaignBaseUrl += `?${newParams.toString()}`;

      PageIndex.doGet(compaignBaseUrl).then((res) => {
        if (res?.status === 200) {
          let compainListModify = res?.data?.data?.map((item) => {
            return {
              _id: item?._id,
              name: item.name || "-",
              email: item.email || "-",
              mobileNumber: item.mobileNumber || "-",
              referralCode: item.referralCode || "-",
              description:
                item.description.length > 10
                  ? `${item.description.slice(0, 5)}...${item.description.slice(
                      -5
                    )}`
                  : item.description
                  ? item.description
                  : "-",
              createdAt: item.createdAt
                ? Index.moment(item.createdAt)
                    .utcOffset("+05:30")
                    .format("DD-MM-YYYY hh:mm A")
                : "-",
            };
          });
          setCurrentPage(res?.data?.currentPage);
          setTotalRecordCount(res?.data?.totalCampaigns);
          setPageDefault(null);
          // setCompainLlist(compainListModify);
          setCompainLlist(compainListModify);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      });
    } catch (error) {}
  };

  //Page laod getCompainList data.
  useEffect(() => {
    getCompainList();
  }, [paginationPerPage, currentPage]);

  //copy of referral link.
  const handleCopy = (data, type) => {
    Index.copyTextToClipboard(`${QRCODE_BASEURL}=${data}`);
    Index.toast.success("Referral Copied");
  };
  // handle Referral QRCode.
  const handleQrScan = (data, type) => {
    setReferralLink(`${QRCODE_BASEURL}=${data}`);
    handleOpen();
  };

  // Handle qr Modal open
  const handleOpen = () => {
    setOpen(true);
  };
  // Handle qr Modal close
  const handleClose = () => {
    setOpen(false);
  };

  //Qrcode style
  const Qrstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  //Called downloadQrCode function which was child componets.
  const downloadQr = () => {
    if (downloadRef?.current) {
      downloadRef?.current?.downloadQrCode();
    } else {
      console.error("Ref is not attached properly.");
    }
  };

  if (permission?.CampaignManagement?.View === true) {
    return (
      <>
        <Index.DynamicTitle title="presale-wraper" />
        <Index.Box className="presale-wraper">
          <Index.Box className="res-cus-table">
            <Index.Box className="offering-cus-table">
              <Index.Box className="offering-table-head tryed">
                <Index.Box className="title-main">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="page-title"
                  >
                    Campaign Management
                  </Index.Typography>
                </Index.Box>

                {permission?.CampaignManagement?.Add === true && (
                  <Index.Box className="common-button blue-button res-blue-button">
                    <Index.RouteLink
                      to="/admin/campaign-master/add"
                      className="no-text-decoration primary-btn-main"
                    >
                      {" "}
                      <Index.Button
                        variant="contained"
                        disableRipple
                        className="primary-btn"
                      >
                        Add Campaign
                      </Index.Button>
                    </Index.RouteLink>
                  </Index.Box>
                )}
              </Index.Box>
            </Index.Box>

            <Index.Box className="border-round-table">
              <Index.TableContainer
                component={Index.Paper}
                className="table-container"
              >
                <Index.Table
                  aria-label="simple table"
                  className="table-design-main supply-table one-line-table invoice-table-set user-management-table trader-list "
                >
                  <Index.TableHead className="gradient-bg">
                    <Index.TableRow>
                      <Index.TableCell align="left">Name</Index.TableCell>
                      <Index.TableCell align="left">Email Id</Index.TableCell>
                      <Index.TableCell align="left">Mobile No</Index.TableCell>
                      <Index.TableCell align="left">
                        Referral Code{" "}
                      </Index.TableCell>
                      <Index.TableCell align="left">Qr Code</Index.TableCell>
                      <Index.TableCell align="left"> Date </Index.TableCell>
                      <Index.TableCell align="left">Action</Index.TableCell>
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody>
                    {campaignList?.length ? (
                      campaignList.map((item, index) => (
                        <Index.TableRow key={item?._id}>
                          <Index.TableCell>{item?.name}</Index.TableCell>
                          <Index.TableCell>{item?.email}</Index.TableCell>
                          <Index.TableCell>
                            {item?.mobileNumber}
                          </Index.TableCell>
                          <Index.TableCell>
                            <Index.Tooltip title="Copy">
                              <Index.Typography
                                className="set-hover-text"
                                variant="p"
                                onClick={() =>
                                  handleCopy(
                                    item?.referralCode,
                                    "Referral_code"
                                  )
                                }
                              >
                                {item?.referralCode}
                              </Index.Typography>
                            </Index.Tooltip>
                          </Index.TableCell>

                          <Index.TableCell>
                            <Index.Link class="text-decoration res-copt-text">
                              <Index.Box class="primary-border-btn-main copy-btn copy-btn-set">
                                <Index.IconButton
                                  onClick={() => {
                                    handleQrScan(item?.referralCode);
                                  }}
                                >
                                  <Index.QrCodeScannerIcon />
                                </Index.IconButton>
                              </Index.Box>
                            </Index.Link>
                          </Index.TableCell>
                          <Index.TableCell>{item?.createdAt}</Index.TableCell>
                          <Index.TableCell>
                            <Index.Button
                              className="admin-table-data-btn"
                              onClick={() => handleView(item)}
                            >
                              <img
                                src={Index.Svg.blueeye}
                                className="admin-icon"
                                alt="View"
                              ></img>
                            </Index.Button>
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          colSpan={10}
                          align="center"
                          className="no-data-cell"
                        >
                          {loading ? <PageLoader /> : "No data found..."}
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                </Index.Table>
              </Index.TableContainer>
            </Index.Box>
            {/* Pagination Comman Components */}
            <CommonPagination
              paginationPerPage={paginationPerPage}
              setPaginationPerPage={setPaginationPerPage}
              setPageDefault={setPageDefault}
              totalRecordCount={totalRecordCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Index.Box>
        </Index.Box>

        {/* </Index.Box > */}

        {/* <Index.DeleteModal
        deleteOpen={deleteOpen}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteClose={handleDeleteClose}
      /> */}

        {/* //Referral Qr Code model */}
        <Index.Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="admin-modal"
        >
          <Index.Box
            sx={Qrstyle}
            className="admin-delete-modal-inner-main admin-modal-inner qr-scanner-modal-inner"
          >
            <Index.Box className="admin-modal-header">
              <Index.Typography
                id="modal-modal-title"
                className="admin-modal-title"
                variant="h6"
                component="h2"
              >
                QR CODE
              </Index.Typography>
              <Index.Button className="modal-close-btn" onClick={handleClose}>
                <img
                  src={PageIndex.Svg.closeblack}
                  className="admin-modal-close-icon"
                  alt="Close"
                />
              </Index.Button>
            </Index.Box>
            <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
              <QrCodeGenerate ref={downloadRef} referralLink={referralLink} />
            </Index.Box>
            <Index.Box className="modal-footer">
              <Index.Box className="admin-modal-user-btn-flex">
                <Index.Box className="btn-main-primary">
                  <Index.Button
                    className="btn-primary-sub"
                    onClick={downloadQr}
                  >
                    Download
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Modal>
      </>
    );
  } else {
    navigate("/admin/dashboard");
  }
}

export default CampaignList;
