import * as Yup from "yup";

const charSpace = (value) =>
  /^[^-\s][a-zA-Z\s]+$/.test(value) || value.length === 0;
const digitsOnly = (value) =>
  /^\d*(?:\.\d*)?$/.test(value) || value?.length === 0;

// const charSpace = (value) =>
//   /^[^-\s][a-zA-Z\s]+$/.test(value) || value.length === 0;

export const adminLoginSchema = Yup.object().shape({
  email: Yup.string()
    .required("Please enter your email")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    ),

  password: Yup.string().required("Please enter your password"),
});

export const adminForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .required("Please enter your email")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    ),
});

export const resetPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required ")
    .min(8, "Password too short. Minimum 8 characters required"),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,20}$/,
  //   "Must contain 8 characters, one uppercase, one lowercase, one number and one special case characters"
  // ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords do not match. Please try again.")
    .required("Confirm password is required"),
});

export const compainMasterValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Name is too short, min 2 characters")
    .max(16, "Name is too long , max 16 characters")
    .required("Name is required"),
  mobileNumber: Yup.string()
    .min(10, "Please Enter valid Mobile Number")
    .max(10, "Please Enter valid Mobile Number")
    .test("inputEntry", "Mobile Number should have digits only", digitsOnly)
    .required("Mobile Number is required"),
  email: Yup.string()
    .email("Enter the valid email")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Enter the valid email"
    )
    .required("Email is required"),
  description: Yup.string()
    .min(2, "Description is too short, min 2 characters")
    .max(200, "Description is too large max 200 characters")
    .required("Description is required"),
});

export const userValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "First name is too short, min 2 characters")
    .max(16, "First name is too long , max 16 characters")
    .required("First name is required")
    .test(
      "First name",
      "First name allows character with space between",
      charSpace
    ),
  lastName: Yup.string()
    .min(2, "Last name is too short ,min 2 characters")
    .max(16, "Last name is too long ,max 16 characters")
    .required("Last name is required")
    .test(
      "Last  name",
      "Last name allows character with space between",
      charSpace
    ),
  email: Yup.string()
    .email("Enter the valid email")
    .matches(
      /^(([^<>()[\]\\.,;:-\s@#"]+(\.[^<>()[\]\\.,;:-\s@#"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Enter the valid email"
    )
    .required("Email is required"),

  mobileNumber: Yup.string()
    .required("Mobile number is required")
    .min(10, "Please enter valid mobile number")
    .max(10, "Please enter valid mobile number"),
  // address: Yup.string().required("Address is required"),
  roleType: Yup.string().required("Role is required"),

  // password: Yup.string().when("isEdit", {
  //   is: false,
  //   then: () =>
  //     Yup.string()
  //       .required("Password is required")
  //       .matches(
  //         /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  //         "Password length should be minimum 8 character including one capital letter, one small letter, one number, and one special character."
  //       ),
  //   otherwise: () => Yup.string().nullable(),
  // }),
  // password: Yup.string()
  //   .required("Password is required")
  //   .min(8, "Password must be at least 8 characters")
  //   .max(16, "Password must be at most 16 characters"),
  // confirmPassword: Yup.string()
  //   .oneOf([Yup.ref("password")], "Passwords do not match. Please try again.")
  //   .required("Confirm password is required"),
  password: Yup.string().when("isEdit", {
    is: false,
    then: () =>
      Yup.string()
        .required("Password is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Password length should be minimum 8 character including one capital letter, one small letter, one number, and one special char."
        ),
    otherwise: () => Yup.string().nullable(),
  }),
  confirmPassword: Yup.string().when("isEdit", {
    is: false,
    then: () =>
      Yup.string()
        .required("Confirm password is required")
        .oneOf(
          [Yup.ref("password")],
          "Passwords do not match. Please try again."
        ),
    otherwise: () => Yup.string().nullable(),
  }),

  profile: Yup.mixed()
    .required("Image is required")
    .test("FileType", "Allow only jpg, jpeg, png file", (value) => {
      if (!value) return true;
      if (!value?.name) {
        const file = value.split(".")[1];
        return ["jpeg", "jpg", "png"].includes(file); //edit
      } else {
        return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type); //add
      }
    }),
});

export const rolePermissionSchema = Yup.object().shape({
  roleName: Yup.string()
    .required("Role name is required")
    .min(3, "Role should be minimum 3 characters long")
    .max(30, "Role should not contain more than 30 characters")
    .matches(
      /^(?!\s)[A-Za-z\s]+$/,
      "Role must not contain numbers or special characters"
    ),
  // permissions: Yup.array().min(1, 'Please select at least one permission')
});

export const coinValidationSchema = Yup.object().shape({
  token_id: Yup.string().required("Token is required"),
  symbol: Yup.string().required("Symbol is required"),
  name: Yup.string().required("Name is required"),
  platform_name: Yup.string().required("Platform name is required"),
  address: Yup.string().required("Address is required"),
  image: Yup.mixed()
    .required("Image is required")
    .test("FileType", "Allow only jpg, jpeg, png file", (value) => {
      if (!value) return true;
      if (!value?.name) {
        const file = value.split(".")[1];
        return ["jpeg", "jpg", "png"].includes(file); //edit
      } else {
        return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type); //add
      }
    }),
});
