import { useEffect, useState } from "react";
import Index from "../../../Index";
import { Formik } from "formik";
import { styled } from "@mui/material";
import { userValidationSchema } from "../../../../validations/Schema";
import PageIndex from "../../../PageIndex";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
function AddUser() {
  const navigate = Index.useNavigate();
  const [image, setImage] = useState("");
  const { state } = Index.useLocation();
  let editData = state?.item;
  const { id } = Index.useParams();
  const [loading, setLoading] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [singleCampaign, setSingleCampaign] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  // InitialValues of form field.
  let initialValues = {};

  if (editData) {
    initialValues = editData;
    initialValues.roleType = editData?.role?._id;
    initialValues.profile = editData?.profile || "";
    initialValues.password = ""; // Edit then password will blank
    initialValues.confirmPassword = "";
  } else {
    initialValues = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      initialValues: "",
      mobileNumber: "",
      roleType: "",
      profile: "",
      isEdit: editData ? true : false,
    };
  }

  //Get list of role list
  const getAllRole = () => {
    try {
      let roleBaseUrl = `${PageIndex.Api.GET_ALL_ROLE_LIST}`;
      PageIndex.doGet(roleBaseUrl).then((res) => {
        if (res?.status === 200) {
          setRoleList(res?.data?.data);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    getAllRole();
  }, []);

  //On Submit passed data of form value.
  const handleFormSubmit = (values, { setSubmitting }) => {
    const data = new FormData();
    if (values?.firstName) {
      data.append("firstName", values.firstName);
    }
    if (values?.lastName) {
      data.append("lastName", values.lastName);
    }
    if (values?.email) {
      data.append("email", values.email);
    }
    if (values?.password !== "" && values?.password !== null) {
      data.append("password", values.password);
    }
    if (values?.mobileNumber) {
      data.append("mobileNumber", values.mobileNumber);
    }

    if (values?.roleType) {
      data.append("roleType", values.roleType);
    }
    if (values?.profile) {
      data.append("profile", values.profile);
    }

    if (editData) {
      data.append("adminId", editData?._id);
    }

    PageIndex.doPost(PageIndex.Api.ADD_EDIT_USER, data).then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        navigate("/admin/user");
      } else {
        setTimeout(() => {
          setSubmitting(false);
        }, 2000);
      }
    });
  };

  const BackBtn = () => {
    navigate("/admin/user");
  };

  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          {loading && id ? (
            <Index.Box className="">
              <Index.Grid container spacing={2}>
                <Index.Grid item xs={5} sm={5} md={5}></Index.Grid>
                <Index.Grid item xs={2} sm={2} md={2}>
                  <Index.CircularProgress color="secondary" size={20} />
                </Index.Grid>
                <Index.Grid item xs={5} sm={5} md={5}></Index.Grid>
              </Index.Grid>
            </Index.Box>
          ) : (
            <>
              <Index.Box className="presale-wraper">
                <Index.Box className="title-header">
                  <Index.Box className="title-header-flex">
                    <Index.Box className="title-main mb-10">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        {singleCampaign ? "Edit" : "Add"} User
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="common-button blue-button save-btn primary-btn-main">
                      <Index.Button
                        variant="contained"
                        type="submit"
                        className="Particular_model_submit ml-0 primary-btn"
                        onClick={BackBtn}
                      >
                        Back
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="form-wrape">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={userValidationSchema}
                    onSubmit={handleFormSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      setFieldValue,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        {console.log(errors, 189)}
                        <Index.Box className="crtl-wrape">
                          <Index.Box className="inner-pad">
                            <Index.Grid container spacing={2}>
                              <Index.Grid item xs={12} sm={6} md={6}>
                                <Index.Box className="inner-pad2">
                                  <Index.Typography
                                    className="admin-form-lable"
                                    component="p"
                                  >
                                    First Name{" "}
                                    <span className="astrick-sing">*</span>
                                  </Index.Typography>
                                  <Index.TextField
                                    hiddenLabel
                                    id="filled-hidden-label-normal"
                                    placeholder="First name"
                                    className="input-fild"
                                    name="firstName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values?.firstName}
                                    error={Boolean(
                                      errors.firstName
                                        ? touched.firstName
                                        : undefined
                                    )}
                                    helperText={
                                      touched.firstName
                                        ? errors.firstName
                                        : undefined
                                    }
                                    variant="standard"
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                  ></Index.TextField>
                                </Index.Box>
                              </Index.Grid>
                              <Index.Grid item xs={12} sm={6} md={6}>
                                <Index.Box className="inner-pad2">
                                  <Index.Typography
                                    className="admin-form-lable"
                                    component="p"
                                  >
                                    Last Name{" "}
                                    <span className="astrick-sing">*</span>
                                  </Index.Typography>
                                  <Index.TextField
                                    hiddenLabel
                                    id="filled-hidden-label-normal"
                                    placeholder="Last name"
                                    className="input-fild"
                                    name="lastName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values?.lastName}
                                    error={Boolean(
                                      errors.lastName
                                        ? touched.lastName
                                        : undefined
                                    )}
                                    helperText={
                                      touched.lastName
                                        ? errors.lastName
                                        : undefined
                                    }
                                    variant="standard"
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                  ></Index.TextField>
                                </Index.Box>
                              </Index.Grid>

                              {!editData && (
                                <Index.Grid item xs={12} sm={6} md={6}>
                                  <Index.Box className="inner-pad2">
                                    <Index.Typography
                                      className="admin-form-lable"
                                      component="p"
                                    >
                                      Email{" "}
                                      <span className="astrick-sing">*</span>
                                    </Index.Typography>
                                    <Index.TextField
                                      hiddenLabel
                                      id="filled-hidden-label-normal"
                                      placeholder="Email"
                                      className="input-fild"
                                      name="email"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values?.email}
                                      error={Boolean(
                                        errors.email ? touched.email : undefined
                                      )}
                                      helperText={
                                        touched.email ? errors.email : undefined
                                      }
                                      variant="standard"
                                      InputProps={{
                                        disableUnderline: true,
                                      }}
                                    ></Index.TextField>
                                  </Index.Box>
                                </Index.Grid>
                              )}

                              <Index.Grid item xs={12} sm={6} md={6}>
                                <Index.Box className="inner-pad2">
                                  <Index.Typography
                                    className="admin-form-lable"
                                    component="p"
                                  >
                                    Mobile Number{" "}
                                    <span className="astrick-sing">*</span>
                                  </Index.Typography>
                                  <Index.TextField
                                    hiddenLabel
                                    id="filled-hidden-label-normal"
                                    placeholder="Mobile number"
                                    className="input-fild"
                                    name="mobileNumber"
                                    onChange={(e) => {
                                      const inputValue = e.target.value.replace(
                                        /[^\d]/g,
                                        ""
                                      );
                                      if (inputValue.toString().length <= 10) {
                                        setFieldValue(
                                          "mobileNumber",
                                          inputValue
                                        );
                                      }
                                    }}
                                    onBlur={handleBlur}
                                    value={values?.mobileNumber}
                                    error={Boolean(
                                      errors.mobileNumber
                                        ? touched.mobileNumber
                                        : undefined
                                    )}
                                    helperText={
                                      touched.mobileNumber
                                        ? errors.mobileNumber
                                        : undefined
                                    }
                                    variant="standard"
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                  ></Index.TextField>
                                </Index.Box>
                              </Index.Grid>

                              {!editData && (
                                <Index.Grid item xs={12} sm={6} md={6}>
                                  <Index.Box className="input-box inner-pad2">
                                    <Index.Typography
                                      className="admin-form-lable"
                                      component="p"
                                    >
                                      Password{" "}
                                      <span className="astrick-sing">*</span>
                                    </Index.Typography>
                                    <Index.Box className="form-group">
                                      <Index.TextField
                                        fullWidth
                                        size="small"
                                        autoComplete="new-password"
                                        name="password"
                                        placeholder="Password"
                                        className="form-control input-with-radius input-fild user-password"
                                        type={
                                          showPassword ? "text" : "password"
                                        }
                                        // label="Password"
                                        variant="outlined"
                                        inputProps={{
                                          className: "input_props",
                                        }}
                                        InputLabelProps={{
                                          className: "add-formlabel",
                                        }}
                                        FormHelperTextProps={{
                                          className: "input_label_props",
                                        }}
                                        onBlur={handleBlur}
                                        value={values.password}
                                        onChange={handleChange}
                                        helperText={
                                          touched.password && errors.password
                                        }
                                        error={Boolean(
                                          errors.password && touched.password
                                        )}
                                        InputProps={{
                                          // <-- This is where the toggle button is added.
                                          endAdornment: (
                                            <Index.InputAdornment position="end">
                                              <Index.IconButton
                                                className="passwrd-eye"
                                                aria-label="toggle password visibility"
                                                onClick={
                                                  handleClickShowPassword
                                                }
                                                onMouseDown={
                                                  handleMouseDownPassword
                                                }
                                                edge="end"
                                              >
                                                {showPassword ? (
                                                  <Index.VisibilityOff />
                                                ) : (
                                                  <Index.Visibility />
                                                )}
                                              </Index.IconButton>
                                            </Index.InputAdornment>
                                          ),
                                        }}
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Grid>
                              )}

                              {!editData && (
                                <Index.Grid item xs={12} sm={6} md={6}>
                                  <Index.Box className="input-box inner-pad2">
                                    <Index.Typography
                                      className="admin-form-lable"
                                      component="p"
                                    >
                                      Confirm password{" "}
                                      <span className="astrick-sing">*</span>
                                    </Index.Typography>
                                    <Index.Box className="form-group">
                                      <Index.TextField
                                        fullWidth
                                        size="small"
                                        name="confirmPassword"
                                        autoComplete="new-password"
                                        placeholder="Confirm password"
                                        className="form-control input-with-radius input-fild user-password"
                                        type={
                                          showConfirmPassword
                                            ? "text"
                                            : "password"
                                        }
                                        // label="Password"
                                        variant="outlined"
                                        inputProps={{
                                          className: "input_props",
                                        }}
                                        InputLabelProps={{
                                          className: "add-formlabel",
                                        }}
                                        FormHelperTextProps={{
                                          className: "input_label_props",
                                        }}
                                        onBlur={handleBlur}
                                        value={values.confirmPassword}
                                        onChange={handleChange}
                                        helperText={
                                          touched.confirmPassword &&
                                          errors.confirmPassword
                                        }
                                        error={Boolean(
                                          errors.confirmPassword &&
                                            touched.confirmPassword
                                        )}
                                        InputProps={{
                                          // <-- This is where the toggle button is added.
                                          endAdornment: (
                                            <Index.InputAdornment position="end">
                                              <Index.IconButton
                                                className="passwrd-eye"
                                                aria-label="toggle password visibility"
                                                onClick={
                                                  handleShowConfirmPassword
                                                }
                                                onMouseDown={
                                                  handleMouseDownConfirmPassword
                                                }
                                                edge="end"
                                              >
                                                {showConfirmPassword ? (
                                                  <Index.VisibilityOff />
                                                ) : (
                                                  <Index.Visibility />
                                                )}
                                              </Index.IconButton>
                                            </Index.InputAdornment>
                                          ),
                                        }}
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Grid>
                              )}

                              <Index.Grid item xs={12} sm={6} md={6}>
                                <Index.Box className="inner-pad2">
                                  <Index.Typography
                                    className="admin-form-lable"
                                    component="p"
                                  >
                                    Role <span className="astrick-sing">*</span>
                                  </Index.Typography>
                                  <Index.Select
                                    className="placeholder-set-text-box user-select"
                                    fullWidth
                                    defaultValue={
                                      values?.roleType ? values?.roleType : ""
                                    }
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    onChange={(e) => {
                                      setFieldValue("roleType", e.target.value);
                                    }}
                                  >
                                    <Index.MenuItem
                                      disabled
                                      value={""}
                                      className="all-select-label default-select"
                                    >
                                      Select role
                                    </Index.MenuItem>
                                    {roleList?.map((items, index) => (
                                      <Index.MenuItem
                                        value={items?._id}
                                        key={items?._id}
                                      >
                                        {items?.role}
                                      </Index.MenuItem>
                                    ))}
                                  </Index.Select>
                                  <Index.Box className="error">
                                    <Index.FormHelperText className="error-message">
                                      {touched?.roleType && errors?.roleType}
                                    </Index.FormHelperText>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Grid>

                              <Index.Grid item xs={12} sm={6} md={6}>
                                {/* <Index.Box className="inner-pad2"> */}
                                <Index.Typography
                                  className="admin-form-lable"
                                  component="p"
                                >
                                  Image
                                  <span className="astrick-sing">*</span>
                                </Index.Typography>
                                <Index.Box className="user-image-btn user-image-btn-box">
                                  <Index.Box>
                                    <Index.Button
                                      component="label"
                                      variant="contained"
                                      className="user-submit-btn-btn"
                                    >
                                      <Index.CloudUpload />
                                      Upload image
                                      <VisuallyHiddenInput
                                        type="file"
                                        name="profile"
                                        accept=".jpeg, .jpg, .png"
                                        onBlur={handleBlur}
                                        onChange={(event) => {
                                          setImage(
                                            event.currentTarget.files[0]
                                          );
                                          setFieldValue(
                                            "profile",
                                            event.currentTarget.files[0]
                                          );
                                        }}
                                      />
                                    </Index.Button>
                                    <Index.Box className="error">
                                      <Index.FormHelperText className="error-message">
                                        {touched?.profile && errors?.profile}
                                      </Index.FormHelperText>
                                    </Index.Box>
                                  </Index.Box>

                                  {!errors?.profile &&
                                    (image || editData?.profile) && (
                                      <Index.Box className="user-img-main">
                                        <img
                                          width={100}
                                          height={100}
                                          src={
                                            image
                                              ? URL.createObjectURL(image)
                                              : editData?.profile
                                              ? `${process.env.REACT_APP_IMAGE_URL_LIVE}${editData?.profile}`
                                              : ""
                                          }
                                          alt=""
                                          className="user-img"
                                        />
                                      </Index.Box>
                                    )}
                                </Index.Box>
                              </Index.Grid>
                            </Index.Grid>
                            <Index.Box className="card-btn-flex">
                              <Index.Box className="primary-btn-main user-submit-btn">
                                <Index.Button
                                  variant="contained"
                                  type="submit"
                                  disabled={isSubmitting}
                                  className="primary-btn"
                                >
                                  {editData ? "Update" : "Submit"}
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </form>
                    )}
                  </Formik>
                </Index.Box>
              </Index.Box>
            </>
          )}
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default AddUser;
