import { useEffect, useState } from "react";
import Index from "../../Index";

import PageIndex from "../../PageIndex";
import {
  adminResendOTP,
  adminVerifyOTP,
} from "../../../redux/slices/AdminService";

export default function Otp() {
  const dispatch = PageIndex.useDispatch();
  const navigate = PageIndex.useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [disable, setDisable] = useState(false);
  const location = Index.useLocation();
  const userId = location?.state?.row?.data?._id;
  const [otp, setOtp] = useState();
  // otp timer
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(59);
  const [resendOtpDisabled, setResendOtpDisabled] = useState(false);

  // OTP change function
  const handleChange = (newValue) => {
    setOtp(newValue);
    if (!isNaN(newValue) && newValue.length === 4) {
      setOtpError();
    }
  };

  const handleFormSubmit = async (action) => {
    setIsDisabled(true);
    const data = {
      otp: +otp,
      id: userId,
    };

    if (!isNaN(otp) && otp?.length === 4) {
      setOtpError("");
      try {
        dispatch(adminVerifyOTP(data)).then((res) => {
          setOtp("");
          setIsDisabled(false);
          if (res?.payload?.status == 200) {
            navigate("/admin/reset-password", {
              state: { userId },
            });
          } else {
            setOtp("");
          }
        });
      } catch (error) {
        setIsDisabled(false);
      }
    } else {
      setOtpError("OTP is required");
      setDisable(false);
    }
  };

  const resendOtp = async () => {
    setResendOtpDisabled(true);
    const data = { id: userId };
    try {
      dispatch(adminResendOTP(data)).then((res) => {
        setResendOtpDisabled(false);
        if (res?.payload?.status == 200) {
          setTimeout(() => {
            setMinutes(1);
            setSeconds(59);
            setResendOtpDisabled(false);
          }, 1000);
        } else {
          setResendOtpDisabled(false);
        }
      });
    } catch (error) {
      setResendOtpDisabled(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  return (
    <Index.Box className="admin-login-main-flex">
      <Index.Box className="admin-login-right-main">
        <Index.Box className="admin-login-box">
          <Index.Box className="admin-login-main">
            <Index.Box className="auth-logo-box">
              <img src={Index.Svg.brand} className="auth-logo" />
            </Index.Box>
            <Index.Box className="admin-login-inner">
              <Index.Typography
                component="h2"
                variant="h2"
                className="admin-wel-text"
              >
                Verify OTP
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="admin-sign-para common-para"
              >
                Please enter the 4 digit code sent on your mail
              </Index.Typography>
              <Index.MuiOtpInput
                className="admin-input-design input-placeholder otp-input-box"
                length={4}
                value={otp}
                onChange={handleChange}
                name="otp"
                inputProps={[{ inputMode: "numeric" }]}
                validateChar={(val) => !isNaN(val)}
              />
              <Index.Box className="otp-error">
                {otpError && (
                  <Index.FormHelperText error className="otp-error-msg">
                    {otpError}
                  </Index.FormHelperText>
                )}
              </Index.Box>
              <Index.Box className="forgot-pass-main">
                <Index.Box className="primary-btn-main">
                  <Index.Button
                    onClick={() => {
                      handleFormSubmit();
                    }}
                    className="primary-btn"
                    disabled={isDisabled}
                  >
                    Verify
                  </Index.Button>
                </Index.Box>
                {seconds > 0 || minutes > 0 ? (
                  <p className="otp-timing">
                    Resend OTP in
                    <span style={{ fontSize: "17px" }}>
                      {" "}
                      {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </span>
                  </p>
                ) : (
                  ""
                )}
                {!(seconds > 0 || minutes > 0) && (
                  <Index.Box className="resend-button-main">
                    <Index.Button
                      onClick={resendOtp}
                      disabled={resendOtpDisabled}
                      className="otp-timing-btn"
                    >
                      Resend Otp
                    </Index.Button>
                  </Index.Box>
                )}
              </Index.Box>
              <Index.Box className="otp-link">
                <Index.Link className="btn-text" to="/admin/login">
                  Back to login
                </Index.Link>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
