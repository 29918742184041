import React, { useState } from "react";
import Index from "../../../Index";
import { ethers } from "ethers";
import presaleAbi from "../home/presaleAbi.json";
import { preSaleAddress } from "../../AddressHelper";
import PageIndex from "../../../PageIndex";
import { Alert, Button } from "@mui/material";
import IconCopy from "../../../../assets/images/svg/copyIcon";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const copiedSuccess = (walletAddress) => {
  navigator.clipboard.writeText(walletAddress);
  PageIndex.toast.success("Copied Successfully", {
    toastId: "1",
  });
};
const PrivateSale = () => {
  const [data, setData] = useState();
  const [tokensData, setTokensData] = useState();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(1);
  const [openView, setOpenView] = React.useState(false);
  const [selectedItemData, setSelectedItemData] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [perPage, setPerPage] = React.useState(6);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationPerPage, setPaginationPerPage] = useState(10);
  const handleOpenView = (data, index) => {
    setPage(1);
    setOpenView(true);
    setSelectedItemData(data);
    setCurrentIndex(index);
  };
  const handleCloseView = () => setOpenView(false);
  const getTime = (time) => {
    const timestamp = time;
    const date = new Date(timestamp * 1000);

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    const formattedDate = `${month} ${day}, ${year} at ${hours}:${minutes}`;
    return formattedDate;
  };
  const allReadData = async () => {
    try {
      setLoading(true);
      const provider = new ethers.providers.JsonRpcProvider(
        "https://bsc-dataseed1.binance.org/"
      );
      const contract = new ethers.Contract(
        preSaleAddress,
        presaleAbi,
        provider
      );
      const getDetails = await contract.getAllPrivatesaleBuyreData();
      const getData = await contract.getTokonomicsData();
      const priceList = await contract.getPhasePrice();

      let totalSupply = 0;
      let totalPLTToken = 0;
      let raisePrice = 0;

      getData.slice(0, 3).map((element, index) => {
        totalSupply += Number(element.totalSuppliedToken.toString()) / 10 ** 18;
        totalPLTToken += Number(element.totalPhaseToken.toString()) / 10 ** 18;
        raisePrice +=
          Number(element.totalPhaseToken.toString() / 10 ** 18) *
          (Number(priceList[index].toString()) / 10000);
      });

      setTokensData({
        totalSupply: totalSupply,
        totalPLTToken: totalPLTToken,
        raisePrice: raisePrice,
      });
      const optimizedData = getDetails.map((element, index) => {
        return {
          phaseName: element.phaseName,
          buyer: element.buyer,
          usdtToken: element.usdtToken?.toString() / 10 ** 18,
          PLTToken: element.pltToken?.toString() / 10 ** 18,
          startTime: Number(element.startTime),
          vestingDuration: Number(element.vestingDuration),
          lastClaimTime: Number(element.lastClaimTime),
          releaseInterval: Number(element.releaseInterval),
          initialCliff: Number(element.initialCliff),
          active: element.active,
          claimTokenTime: getTime(
            Number(element.lastClaimTime) + Number(element.releaseInterval)
          ),
          index: index + 1,
        };
      });
      setData(optimizedData);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useState(() => {
    allReadData();
  }, []);
  const totalPages = Math.ceil(data?.length / paginationPerPage);

  const paginatedData =
    data &&
    data.slice(
      (currentPage - 1) * paginationPerPage,
      currentPage * paginationPerPage
    );
  return (
    <>
      <Index.Box className="presale-wraper">
        <Index.Box className="stat-box">
          <Index.Box className="stat">
            <Index.Box className="text-wrape">
              <Index.Typography component="h6">
                {tokensData ? Number(tokensData?.totalPLTToken).toFixed(2) : 0}
              </Index.Typography>
              <Index.Typography component="p">Total Token</Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="stat unlock">
            <Index.Box className="text-wrape">
              <Index.Typography component="h6">
                {tokensData ? Number(tokensData?.totalSupply).toFixed(2) : 0}
              </Index.Typography>
              <Index.Typography component="p">
                Total Supplied Token
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="stat available">
            <Index.Box className="text-wrape">
              <Index.Typography component="h6">
                {tokensData ? Number(tokensData?.raisePrice).toFixed(2) : 0}
              </Index.Typography>
              <Index.Typography component="p">Raise USDT</Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="border-round-table">
          <Index.TableContainer
            component={Index.Paper}
            className="table-container"
          >
            <Index.Table
              aria-label="simple table"
              className="table-design-main supply-table one-line-table invoice-table-set user-management-table trader-list "
            >
              <Index.TableHead className="gradient-bg">
                <Index.TableRow>
                  <Index.TableCell align="left"> No </Index.TableCell>
                  <Index.TableCell align="left">Phase</Index.TableCell>
                  <Index.TableCell align="left">Wallet Address</Index.TableCell>
                  <Index.TableCell align="left"> USDT </Index.TableCell>
                  <Index.TableCell align="left"> PLT </Index.TableCell>
                  <Index.TableCell align="left">Start Duration</Index.TableCell>
                  <Index.TableCell align="left">Vesting Ends</Index.TableCell>
                  <Index.TableCell align="left">Next Round</Index.TableCell>
                  <Index.TableCell align="left">View</Index.TableCell>
                </Index.TableRow>
              </Index.TableHead>
              {loading ? (
                <Index.Loading />
              ) : (
                <Index.TableBody>
                  {paginatedData?.map((element) => (
                    <Index.TableRow key={element?._id}>
                      <Index.TableCell>{element?.index}</Index.TableCell>
                      <Index.TableCell>{element?.phaseName}</Index.TableCell>
                      <Index.TableCell>
                        <Index.Box className="copy-flex">
                          <Index.Box className="copy-url-text">
                            {element?.buyer.slice(0, 6)}...
                            {element.buyer.slice(-4)}
                          </Index.Box>
                          <Index.Button
                            onClick={() => copiedSuccess(element?.buyer)}
                          >
                            <IconCopy className="copy-icon" />
                          </Index.Button>
                        </Index.Box>
                      </Index.TableCell>
                      <Index.TableCell>
                        {Number(element?.usdtToken).toFixed(2)}
                      </Index.TableCell>
                      <Index.TableCell>
                        {Number(element?.PLTToken).toFixed(2)}
                      </Index.TableCell>
                      <Index.TableCell>
                        {" "}
                        {getTime(element?.startTime)}{" "}
                      </Index.TableCell>
                      <Index.TableCell>
                        {" "}
                        {getTime(element?.vestingDuration)}{" "}
                      </Index.TableCell>
                      <Index.TableCell>
                        {element.lastClaimTime === element.vestingDuration
                          ? "Completed"
                          : element.claimTokenTime}
                      </Index.TableCell>
                      <Index.TableCell
                        align="right"
                        className="actions"
                        onClick={() => handleOpenView(element)}
                      >
                        <Index.Box className="table-btn">
                          <PageIndex.Svg.ViewIcon />
                        </Index.Box>
                      </Index.TableCell>
                    </Index.TableRow>
                  ))}
                </Index.TableBody>
              )}
            </Index.Table>
            <Index.Box style={{ marginLeft: "77%" }}>
              <Button
                className="pagination-button black-border"
                onClick={() => {
                  if (currentPage > 1) {
                    setCurrentPage(currentPage - 1);
                  }
                }}
              >
                Prev
              </Button>
              <span className="pagination-text">Page {currentPage}</span>
              <Button
                className="pagination-button black-border"
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
              >
                Next
              </Button>
            </Index.Box>
            <div style={{ marginBottom: "20px" }} />
          </Index.TableContainer>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={openView}
        onClose={handleCloseView}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-wrape view-detail"
      >
        <Index.Box sx={style} className="modal-pad modal-containt-main">
          <Index.Box className="modal-head">
            View Detail
            <Index.Link onClick={handleCloseView} className="modal-close">
              <PageIndex.Svg.ModalCloseIcon />
            </Index.Link>
          </Index.Box>
          <Index.Box className="modal-body view-body-content">
            <Index.Box className="account-data-ul">
              <Index.Box className="account-data-li">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="account-li-lable"
                >
                  USDT:
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="account-li-data"
                >
                  {selectedItemData?.phaseName === "Founders/Team" ||
                  selectedItemData?.phaseName === "Advisors"
                    ? 0
                    : Number(selectedItemData?.usdtToken).toFixed(2)}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="account-data-li">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="account-li-lable"
                >
                  PLT:
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="account-li-data"
                >
                  {selectedItemData
                    ? Number(selectedItemData.PLTToken).toFixed(2)
                    : 0}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="account-data-li">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="account-li-lable"
                >
                  Vesting Start:
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="account-li-data"
                >
                  {selectedItemData ? getTime(selectedItemData.startTime) : 0}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="account-data-li">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="account-li-lable"
                >
                  Vesting Ends:
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="account-li-data"
                >
                  {selectedItemData
                    ? getTime(selectedItemData.vestingDuration)
                    : 0}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="view-wrapper">
            <Index.Box className="view-flex">
              <Index.Box className="view-content">Release Token</Index.Box>
              <Index.Box className="view-content">Release Date</Index.Box>
              <Index.Box className="view-content">Status</Index.Box>
            </Index.Box>
          </Index.Box>
          {Array.from(
            {
              length: 24,
            },
            (_, index) => {
              if (index >= (page - 1) * perPage && index < page * perPage) {
                const founder_Advisor =
                  selectedItemData?.phaseName === "Founders/Team" ||
                  selectedItemData?.phaseName === "Advisors"
                    ? "Founders/Team"
                    : "Presale";

                const completed =
                  founder_Advisor === "Presale"
                    ? Number(
                        selectedItemData?.lastClaimTime -
                          Number(
                            selectedItemData?.startTime +
                              selectedItemData?.initialCliff
                          )
                      ) / Number(selectedItemData?.releaseInterval)
                    : Number(
                        selectedItemData?.lastClaimTime -
                          Number(
                            selectedItemData?.startTime +
                              selectedItemData?.initialCliff
                          )
                      ) / Number(selectedItemData?.releaseInterval);
                let available =
                  (Math.floor(Date.now() / 1000) -
                    Number(selectedItemData?.lastClaimTime)) /
                    Number(selectedItemData?.releaseInterval) +
                  completed;

                const alertVariant =
                  index + 1 <= completed
                    ? "success"
                    : index + 1 <= available
                    ? "info"
                    : "warning";
                const alertText =
                  index + 1 <= completed
                    ? "Completed"
                    : index + 1 <= available
                    ? "Available"
                    : "Coming Soon";

                return (
                  <Alert
                    key={index}
                    variant="filled"
                    severity={alertVariant}
                    icon={false}
                    className="alert-wrape view-alert-box"
                    sx={{
                      padding: "10px",
                      background:
                        alertVariant === "success"
                          ? "#75d57a"
                          : alertVariant === "info"
                          ? "#7bb4d3"
                          : "#e79654",
                      textAlign: "center",
                      margin: "4px 10px",
                      fontSize: "10px",
                    }}
                  >
                    <Index.Box className="view-flex">
                      <Index.Box className="view-content">
                        {Number(selectedItemData?.PLTToken / 24).toFixed(2)}
                        PLT
                      </Index.Box>
                      <Index.Box className="view-content">
                        {getTime(
                          founder_Advisor === "Presale"
                            ? String(
                                Number(selectedItemData?.startTime) +
                                  Number(selectedItemData?.releaseInterval) *
                                    (index + 1) +
                                  Number(selectedItemData?.initialCliff)
                              )
                            : String(
                                Number(selectedItemData?.startTime) +
                                  Number(selectedItemData?.releaseInterval) *
                                    (index + 1) +
                                  Number(selectedItemData?.initialCliff)
                              )
                        )}
                      </Index.Box>
                      <Index.Box className="view-content">
                        {alertText}
                      </Index.Box>
                    </Index.Box>
                  </Alert>
                );
              }
            }
          )}
          <Button
            className="view-content-btn"
            onClick={() => {
              if (page > 1) {
                setPage(page - 1);
              }
            }}
          >
            Prev
          </Button>
          Page {page}
          <Button
            className="view-content-btn"
            onClick={() => {
              setPage(page + 1);
            }}
          >
            Next
          </Button>
          <div style={{ marginBottom: "20px" }} />
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default PrivateSale;
